import React, {useEffect, useState} from "react";
import Slider from "react-slick";
import dic from "i18next";
import Select from 'react-select';
import "./style.scss";
import CustomNextArrow from "../../library/baaShared/CustomArrow/CustomNextArrow";
import CustomPrevArrow from "../../library/baaShared/CustomArrow/CustomPrevArrow";
import left from "../../assets/leftgrey-arrow.png";

const PersonalTrainer = (props) => {
  const [selectedOption, setselectedOption] = useState(null);
  const Trainers = props && props.fields.Trainers;
  const [tempTrainers, settempTrainers] = useState(Trainers);
  const title = props && props.fields && props.fields.Title.value;

  //only for react select dropdown list 
  let options = [ { label : 'Select Speciality', value : "Show All" } ];
  props && props.fields && props.fields.Specialties.forEach( item => (
    options.push({ label : item.fields.Title.value , value : item.fields.Title.value })
  ));

  useEffect(() => {
    // const hotelname = props && props.fields && props.fields["Hotel Names"] && props.fields["Hotel Names"][0].fields.Title.value;
    // let temp = [];
    // Trainers.forEach((item) =>  { 
    //   const personaltrainershotelname = item.fields && item.fields["Hotel Name"].fields && item.fields["Hotel Name"].fields.Title && item.fields["Hotel Name"].fields.Title.value;
    //   return ( personaltrainershotelname === hotelname ) ? temp.push(item):'' 
    // });
  },);
  
  //filter function for Personal Trainers
  const sorttrainers = (Specialities) => {
    let temp = [];
    Trainers.forEach(x =>  x.fields["Specialities"].some((item)=> item.fields.Title.value === Specialities) ? temp.push(x):'');
    settempTrainers(temp);
  }

  //handle change event for selecting value from react select list
  const handleChange = (selectedOption) => {
    setselectedOption(selectedOption);
    settempTrainers(Trainers);
    if(selectedOption.value !== "Show All"){
        sorttrainers(selectedOption.value);
    }
  }

  //settings for slider
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <CustomNextArrow img={left} />,
    prevArrow: <CustomPrevArrow img={left} />,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  //custom styles for react select dropdown
  const customStyles = {
    control: (styles,state) => ({ 
        ...styles,  
        border: state.isFocused ? 0 : '',
        borderRadius: 6, 
        minHeight:'28px',
        height:'28px',
        borderColor:'#abb8bd',
    }),
    indicatorSeparator: (styles) => ({
        ...styles,
        display:'none',
    }),
    dropdownIndicator: (base) => ({
        ...base,
        position: "relative",
        top:"-5px",
        color:'#abb8bd',
    }),
    clearIndicator: (base) => ({
        ...base,
        position: "relative",
        top:"-5px",
        paddingRight:"0px"
    }),
    valueContainer: (defaultStyles) => {
        return {
            ...defaultStyles,
            position: 'relative',
            top:'-5px',
            textAlign:'left',
            color:'black',
        }
    },
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color:'#abb8bd',
            fontSize:'14px',
            textAlign:'left'
        }
    },
    menuList: (defaultStyles) => {
      return {
          ...defaultStyles,
          "::-webkit-scrollbar": {
            width: "5px"
          },
          "::-webkit-scrollbar-track": {
            background: '#f1f1f1',
            boxShadow: 'inset 0 0 5px grey',
            borderRadius: '5px',
          },
          "::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: '5px',
          },
      }
    },
  }
  
  return (
    <div className="container-fluid personal-trainer-main-profile">
      <div className="personal-trainer">
        <h2 className="title py-2">{title}</h2>
        {/* <div className="filters">
          <div className="row">
          <div className="col-md-1 col-1 col-lg-2 col-sm-3"></div>
          <div className="col-md-10 col-10 col-lg-8 col-sm-6  text-center">
          <div className="row m-0 justify-content-center">
              <span className="filter-label mobile-filter-adjust">{dic.t("Jclub-Filter By Locations:")}</span>
              <Select options={options} isSearchable={true} defaultValue={options[0]} className="col-md-6 col-xl-4 px-0" onChange={handleChange} placeholder="SHOW ALL" styles={customStyles}/>
            </div>
            </div>
            <div className="col-md-2 col-2 col-lg-3 col-sm-3"></div>
          </div>
        </div> */}
      </div>
      <div className="personal-slider-main-location">
        <Slider {...settings}>
          {tempTrainers.map((item, index) => {
            const image = item.fields["Background Image"] && item.fields["Background Image"].value.src;
            const name = item.fields && item.fields.Name && item.fields.Name.value;
            // const location = item.fields["Hotel Name"] && item.fields["Hotel Name"].fields && item.fields["Hotel Name"].fields.Title.value;
            const href = item.fields["CTA"] && item.fields["CTA"].value.href;
            return (
              <div key={index}>
                <div className="personal-slider">
                  <a href={href} className="text-reset outline-none text-decoration-none" >
                  <img src={image} className="img-fluid" alt="profile"/>
                  <div className="profile">
                    <h3 className="title">{name}</h3>
                    {/* <p className="description">{location}</p> */}
                    <div className="text-center">
                      <p  className="Learn-more">
                        {dic.t("Jclub-Learn More")}
                      </p>
                    </div>
                  </div>
                 </a>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      <br />
    </div>
  );
};

export default PersonalTrainer;
