
import React from 'react'
// import PrevArrow from './../../../assets/images/icons/white.png';
import './CustomArrow.scss'
export default function customNextArrow(props) {
    // hide the arrow if it is last one
    if (!props.onClick) {
        return null;
    }
    return (
        <div className={props.className} onClick={() => {
            props.onClick();
            props.onclick && props.onclick()
        }} >
            <img src={props.img} alt="left arrow" />
        </div>
    )
}
