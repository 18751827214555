import React from 'react';
import trans from "../../../assets/trans.png";
import dic from "i18next";
import './style.scss';
function Newscard(props) {
    const { title, image , readmore} = props;
 
    return (
        <>
            <div className="card border-0" key={props.keyvalue} >
        <a href={readmore}  className="text-reset   text-decoration-none " >
                <img className="card-img-top" src={image} alt="Cardcap" />
                    <div className="card-body text-center">
                        <p className="card-title">{title}</p>
                        <p  className="text-muted readmore text-decoration-none font-weight-bold">{dic.t("Jclub-Read more")}</p>
                    </div>
                    </a>
            </div>
        </>
    )
}

export default Newscard