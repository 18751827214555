import React,{useEffect, useRef} from "react";
import "./Facilities.scss";
import dic from "i18next";
import Slider from "react-slick";
import FacilitiesMobile from "./FacilitiesMobile";

export const Facilities = (props) =>{
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        fade:true,
        autoplay:true,
        autoplaySpeed: 3000,
    }
    var facilities_settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      fade:true,
      autoplay:true,
      autoplaySpeed: 10000,
      arrows:false
  }
    const Images = props && props.fields && props.fields["Carousal Images"];
    const Title = props && props.fields && props.fields["Overview Title"].value;
    const Description = props && props.fields && props.fields["Overview Description"].value;
    // const RightImage = props && props.fields && props.fields["Overview Image"].value.src;
    // const showvideo = props && props.fields && props.fields["Show Video"] && props.fields["Show Video"].value;
    const videolink = props && props.fields && props.fields["Overview Videos"];
    return <div className="facilities" >
               <div className='mobile-view'>
                  <FacilitiesMobile Title={Title} Description={Description} Images={Images} />
                </div>
               <div className="container-fluid facilities-desktop-view desktop-view">
                   <div className="row">
                       <div className="col-md-1  col-lg-2 col-sm-2"></div>
                       <div className="col-md-5  col-lg-5 col-sm-5 left-banner p-1">
                            <div className="facilities-vertical-background">
                                 <p className="facilities-vertical-title" >{dic.t("Jclub-Facilities")}</p>
                            </div>
                            <Slider {...settings}>
                                {
                                    Images && Images.map((item,index)=>{
                                        const srcimage = item.fields["Background Image"] && item.fields["Background Image"].value  && item.fields["Background Image"].value.src;
                                        return <img src={srcimage}  className="img-fluid image-bg" key={index} alt="facilities" />
                                    })
                                }
                            </Slider>
                            <div className="facilties-content pt-4">
                                <h2 className="title py-2">{Title}</h2>
                                <div className="paragraph">
                                <p dangerouslySetInnerHTML={{ __html: Description }}></p>
                                </div>
                            </div>
                       </div>
                       <div className="col-md-5 col-lg-5 col-sm-5 p-1 right-banner">
                                <div className="py-5" ></div>
                              {
                                videolink && videolink.length > 0 ? 
                                    <Slider {...facilities_settings} >
                                        {
                                            videolink.map( (item) => {
                                              const currentlink = item && item.fields["Video Link"] &&  item.fields["Video Link"].value && item.fields["Video Link"].value.href;
                                                return <video
                                                className="bigbuild" 
                                                src={currentlink}
                                                muted
                                                autoPlay
                                                loop
                                                />
                                            } )
                                        }
                                    </Slider>
                                    : ''
                              }
                           
                       </div>
                   </div>
               </div>
           </div>
}

export default Facilities;
