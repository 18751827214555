import React from "react";
import dic from "i18next";
import './style.scss';
const Timetable = (props) =>{
    const background = props && props.fields && props.fields["Background Image"].value.src;
    const icon = props && props.fields && props.fields["Time Table Icon"].value.src; 
    const title = props && props.fields && props.fields["Title"].value;
    const subtitle = props && props.fields && props.fields["Sub Title"].value;
    const pdflink = props && props.fields && props.fields.CTA.value.href;
    return <div className="timetable-main" style={{backgroundImage: `linear-gradient(rgba(0,0,0,0.75),rgba(0,0,0,0.75)),url(${background})`}}  >
               <p className="mobile-timetable" >{dic.t("Jclub-View Our Timetables")}</p>
               <div className="timetable-desktop-section">
                     <h2 className="title">{title}</h2>
                     <img src={icon} alt="icon-timetable" className="timetable-icon"/>
                     <p className="first-p p-0 m-0 mt-2" >{subtitle}</p>
                     <a className="second-p" href={pdflink} target="blank" download="MENU">{dic.t("Jclub-View Our Timetable")}</a>
               </div>
          </div>
}

export default Timetable;