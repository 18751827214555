import React, { useState } from "react";
import Select from 'react-select';
import dic from "i18next";
import './style.scss';

const PersonalTrainersHome = (props) =>{
    const [selectedOption, setselectedOption] = useState(false);
    const [tempTrainers, settempTrainers] = useState(props && props.fields.Trainers);
    const Trainers = props && props.fields.Trainers;
    const title = props && props.fields.Title.value;

    const options = [{ value: "Show All", label: "Select Location" }];
    props && props.fields && props.fields["Hotel Names"].forEach( item => (
        options.push({ label : item.fields.Title.value , value : item.fields.Title.value })
      ));
    
      const customStyles = {
        control: (styles,state) => ({ 
            ...styles,  
            border: state.isFocused ? 0 : '',
            borderRadius: 6, 
            minHeight:'28px',
            height:'28px',
            borderColor:'#abb8bd',
        }),
        indicatorSeparator: (styles) => ({
            ...styles,
            display:'none',
        }),
        dropdownIndicator: (base) => ({
            ...base,
            position: "relative",
            top:"-5px"
        }),
        clearIndicator: (base) => ({
            ...base,
            position: "relative",
            top:"-5px",
            paddingRight:"0px"
        }),
        valueContainer: (defaultStyles) => {
            return {
                ...defaultStyles,
                position: 'relative',
                top:'-5px',
                textAlign:'left'
            }
        },
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                color:'#abb8bd',
                fontSize:'14px',
                textAlign:'left'
            }
        },
    }
      const sorttrainers = (name) => {
        let temp = Trainers;
        temp = temp.filter(x => x.fields["Hotel Name"].fields.Title.value === name);
        settempTrainers(temp);
      }
    const handleChange = (selectedOption) => {
        setselectedOption(selectedOption);
        settempTrainers(Trainers);
        if(selectedOption.value !== "Show All"){
            sorttrainers(selectedOption.value);
        }
    }
    return   <div className="container-fluid personal-trainer-main-home">
                    <div className="personal-trainer"> 
                        <h2 className="title">{title}</h2>
                        {/* <div className="filters">
                            <div className="row">
                                <div className="col-md-1 col-1 col-lg-2 col-sm-3"></div>
                                <div className="col-md-10 col-10 col-lg-8 col-sm-6  text-center">
                                        <div className="row m-0 justify-content-center"> */}
                                    {/* <div className="filter-label col-md-3  px-0 mobile-filter-adjust" >Filter By Locations: </div> */}
                                    {/* <span className="filter-label mobile-filter-adjust" >{dic.t("Jclub-Filter By Locations")}</span>
                             
                                    <Select options={options} isSearchable={true} defaultValue={options[0]} onChange={handleChange} className="col-md-6 col-xl-4 px-0" placeholder="SHOW ALL" styles={customStyles}/>
                                     
                                    </div>
                                </div>
                                <div className="col-md-1 col-1 col-lg-2 col-sm-3"></div>
                            </div>
                      </div>   */}
                    </div>
                <div className="personal-slider-main-home my-3">
                <div className="row"> 
                   {
                       tempTrainers.map((item,index) =>{
                        const image = item.fields["Background Image"] && item.fields["Background Image"].value.src;
                        const name = item.fields && item.fields.Name && item.fields.Name.value;
                        // const location = item.fields["Hotel Name"].fields.Title.value;
                        const href = item.fields["CTA"] && item.fields["CTA"].value.href;
                            return  <div className="col-md-4 col-6 col-sm-4 col-lg-3 padding-adjust" key={index}>  
                            <div className="card" key={index}>
                                <div className="personal-slider" >
                                     <a href={href} className="text-reset outline-none text-decoration-none" >
                                        {/* <img src={item.src} className="card-img-top" /> */}
                                        <div className="profile-bg-image" style={{backgroundImage: `url(${image})`}} ></div>
                                        <div className="profile">
                                            <h3 className="title">{name}</h3>
                                            {/* <p className="description" >{location}</p> */}
                                            <div className="text-center Learn-more-head">
                                                <p className="Learn-more" >{dic.t("Jclub-Learn More")}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div> 
                            </div>
                          </div>
                       })
                   } 
                </div>
            </div>
        </div>  
}

export default PersonalTrainersHome;