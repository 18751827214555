import React, { Component, Fragment } from 'react';
import "./offerCard.scss";
import dic from "i18next";
import ReadMoreButton from './ReadMoreButton';
class OfferCard extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            openbox:false,
        }
    }

    setopenbox = () => {
        this.setState({openbox:!this.state.openbox});
    }

    
  render() {
    const { duration , colors , index , contentlist} = this.props;
    var colorcode = colors[index];
    const {fields } = contentlist[index];
    const Name = fields.Title && fields.Title.value;
    const ShortDescription = fields["Short Description"] && fields["Short Description"].value;
    const srcimagecontent = fields["Background Images"] && fields["Background Images"][0];
    const readmore = fields["Read More"] && fields["Read More"].value.href;
    const srcimage =  srcimagecontent.fields["Background Image"] && srcimagecontent.fields["Background Image"].value && srcimagecontent.fields["Background Image"].value.src;
    //  data-aos-once="true"  
    return ( 
        <Fragment>
        <div className='card-parent card-list-main cg-offercard-main' data-aos-easing="ease-in-out" data-aos="fade-right" data-aos-offset="200" data-aos-delay={duration} key={this.props.index}>
             <a href={readmore} className='text-reset text-decoration-none'> 
                <div className='card ssss' style={{ backgroundColor:`${colorcode}`}}>
                    <div className='inner-card'>
                        <div className='image-wrapper'>
                            <img src={srcimage} alt="card" className='card-image'/>
                        </div>
                        <div className='content'>
                            <h3 className='content-title'>{Name}</h3>
                            <div className='content-description'><p dangerouslySetInnerHTML={{ __html: ShortDescription }}></p></div>
                        </div>
                        <div className='read-more-button'>
                            <button>{dic.t("Jclub-Read more")}</button>
                        </div>
                    </div>
                </div>
            </a>
         </div>
           <ReadMoreButton openbox={this.state.openbox} update={this.setopenbox} index={this.props.index} contentlist={this.props.contentlist}/>
        </Fragment>
    );
  }
}

export default OfferCard;
