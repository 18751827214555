import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
  } from "@reach/accordion";
import "@reach/accordion/styles.css";
import {FiChevronDown} from 'react-icons/fi';
import './style.scss';
import NotFoundFAQ from "../NotFoundFAQ";
import dic from "i18next";

export default ({
    faqList
}) => {
    return (
        <div className="jclub-faq-accoridon-container">
        { faqList && faqList.length > 0 ? <>
            <Accordion>
                {faqList.length > 0 && faqList.map( (data,key) => {
                        return (
                            <AccordionItem index={key}>
                                <div className="position-relative">
                                    <AccordionButton>
                                        <p>{data.fields.Question.value}</p>
                                        <FiChevronDown/>
                                    </AccordionButton>
                                </div>
                                <AccordionPanel dangerouslySetInnerHTML={{__html: data.fields.Answer.value }}>
                                </AccordionPanel>
                            </AccordionItem>
                        )
                    }
                )}
            </Accordion>
            </> : <>
            <NotFoundFAQ />
            </>
        }
    </div>
    );
}