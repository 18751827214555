import React from 'react';
import Navbar from './Navbar';


const Header = (props) => {
    return (<>
          <Navbar logo={props.fields.Logo.value.src}  social_links={props.fields["Social Links"]} navigation={props.fields["Primary Navigations"]} links={props.fields["External Navigations"]} topnavigation={props.fields["Top Navigation"]} />
    </>);
}

export default Header;