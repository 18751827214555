import React from 'react';
import dic from "i18next";
import './style.scss';
import Slider from "react-slick";

const Dining = (props) =>{
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay:true,
        autoplaySpeed:3000,
        fade:true
    }
    const casual_items = props && props.fields && props.fields.Casuals;
    const fine_dining = props && props.fields && props.fields["Fine Dining"];
    const Images = props && props.fields && props.fields.Images;
    const findoutmore = props && props.fields &&  props.fields["Find Out CTA"] && props.fields["Find Out CTA"].value.href;
    return <div className='dining-main-container'>
                <div className='banner-container container' >
                    <div className='row'>
                        <div className='col-1 col-md-1 col-sm-1 col-lg-1 col-xl-1'></div>
                        <div className='col-10 col-md-10 col-sm-10 col-lg-10 col-xl-10'>
                            <Slider {...settings}>
                                {
                                    Images && Images.map( (item ,index) => {
                                        const src = item && item.fields && item.fields["Background Image"] && item.fields["Background Image"].value.src;
                                        return(
                                        <img src={src} className="img-fluid image-bg" alt="Dinning" key={index} />
                                        )
                                    })
                                }
                             </Slider>
                        </div>
                        <div className='col-1 col-md-1 col-sm-1 col-lg-1 col-xl-1'></div>
                    </div>
                </div>
                <div className='col-md-12 col-12 text-center pt-4' >
                    <a href={findoutmore} target='_blank' className='text-reset text-decoration-none'  rel="noopener noreferrer"  ><button className="findout-more btn">Find Out More</button></a>              
                </div>
                <div className='container dinning-description'>
                    <div className='list-items' >
                    <div className='row'>
                        <div className='col-1 col-md-1 col-sm-1 col-lg-1 col-xl-1'></div>
                        <div className='col-10 col-md-10 col-sm-10 col-lg-10 col-xl-10'>
                                 <table className="table table-striped">
                                    <thead className="thead-light">
                                        <tr>
                                         <th scope="col" colSpan={12}>{dic.t("Jclub-Casual")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            casual_items.map( (item,index) =>{  
                                           return  <tr key={index}>
                                                <td>{item.fields["Dish Name"].value}</td>
                                                <td>{item.fields["Hotel Name"].value}</td>
                                            </tr>
                                            })
                                        }
                                     </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='list-items' >
                    <div className='row'>
                        <div className='col-1 col-md-1 col-sm-1 col-lg-1 col-xl-1'></div>
                        <div className='col-10 col-md-10 col-sm-10 col-lg-10 col-xl-10'>
                                 <table className="table table-striped">
                                    <thead className="thead-light">
                                        <tr>
                                         <th scope="col" colSpan={12}>{dic.t("Jclub-Fine Dining")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            fine_dining.map( (item,index) =>{  
                                           return  <tr key={index}>
                                                <td>{item.fields["Dish Name"].value}</td>
                                                <td>{item.fields["Hotel Name"].value}</td>
                                            </tr>
                                            })
                                        }
                                     </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
               </div>   
           </div>

}

export default Dining;
