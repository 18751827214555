import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import dic from "i18next";
import Accordion from './Accordian';
import "./Comparison.scss";

const Comparison = (props) => {
    const isMobile = useMediaQuery({ query: '(max-width: 1048px)' })
    const [activeIndex, setActiveIndex] = useState(0);
    const data = props && props.fields["Comparison Types"];
    const info = props && props.fields["Star Hotels"] && props.fields["Star Hotels"].value;
    useEffect(() => {
        if(activeIndex !== 0){
            const element =document.getElementById(`faq${activeIndex + 1}_desc`);
            const yOffset = -120; 
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            if(typeof window !== 'undefined'){
                window.scrollTo({top: y, behavior: 'smooth'});
            }
        }
    }, [activeIndex]);
    
    const renderedMembership = data && data.map((item, index) => {
        console.log(item,"ITEMS")
        const showDescription = index === activeIndex ? "show-description" : "";
        const fontWeightBold = index === activeIndex ? "democlass" : "";
        const ariaExpanded = index === activeIndex ? "true" : "false";
        return (
            <Accordion
                showDescription={showDescription}
                fontWeightBold={fontWeightBold}
                ariaExpanded={ariaExpanded}
                item={item}
                key={index}
                info={info}
                index={index}
                onClick={() => {
                    setActiveIndex(index);
                }}
            />
        );
    });

    return (<>
        <div className="membership">
            <div className='membership-container'>
                <div className='title'>
                    <h2 data-aos="fade-up" data-aos-duration="1200">{dic.t("Jclub-Membership Comparison")}</h2>
                </div>
                {/* <div className='subtitle'>
                    <div></div>
                    <div className='subtitle-list'>
                        <div className='life'><h3>{dic.t("Jclub-LifeStyle")}</h3></div>
                        <div className='fit'><h3>{dic.t("Jclub-Fitness")}</h3></div>
                        <div className='city'><h3>{dic.t("Jclub-City")}</h3></div>
                    </div>
                </div> */}
                <dl className='list'>{renderedMembership}</dl>
                {/* <div className={`${!isMobile ? 'sub-info' : 'mobile-sub-info'}`}>
                    <p>{ !isMobile ? info : info.toString().replaceAll("|","")}</p>
                </div> */}
            </div>
        </div>
  </> );
}

export default Comparison;
