import React from 'react';
import dictionary from "i18next";
import "./ErrorPage.scss";

const ErrorPage = (props) => (
//   <div className="error-page-wrapper">
//   <h1>{props.fields["Title"] && props.fields["Title"].value}</h1> 
//   <p>
//     {props.fields["Subtitle"] && props.fields["Subtitle"].value}
//   </p>
//   <a href={props.fields["CTA link"] && props.fields["CTA link"].value && props.fields["CTA link"].value.href}>{dictionary.t("ErrorPage-NeedHelpText", "ErrorPage-NeedHelpText")}</a>
// </div>
<div className="error-page-wrapper">
  <h1>Page not found</h1>
  {/* <p>
    The requested page cannot be found. Please contact our Jclub team at
    the link below for further assistance. Thank you.
  </p> */}
  <a href="/en/home">Go To Thejclub.com</a>
</div>

);

export default ErrorPage;



