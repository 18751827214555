import React,{useState,useEffect} from 'react';
import NewsList from './NewsList';
import "./News.scss";
import NewsArticle from './NewsArticle';



const News = (props) => {
    const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
    const { fields } = props;
    const featuredNews = fields["Featured News"];
    const News = fields["News"];
    const NewsTitle = fields.Title.value;
    const firstcolumn = [];
    const secondcolumn = [];
    const [getTitle,setGetTitle] = useState('');
    const [FeaturedTitle,setFeaturedTitle] = useState('');


    featuredNews && featuredNews.map((item,index) =>{
        if(index === 0){
            firstcolumn.push(item);
        }else{
            secondcolumn.push(item);
        }
        return true;
    })

    var urltitle = "";
    var featuredtitle = "";
    useEffect( () =>{
 
         const queryParams = new URLSearchParams(window.location.search);
         urltitle = queryParams.get('title');
         featuredtitle = queryParams.get('featuredtitle');
         setGetTitle(urltitle);
         setFeaturedTitle(featuredtitle);

    },[])

    return (
        <><div className='news-info-section'><div className='news-info-title mx-auto py-0' >
        <h2>{NewsTitle}</h2></div>
      </div>{
            FeaturedTitle ? <NewsArticle newslist={featuredNews}  urltitle={FeaturedTitle}/> :  
           
              getTitle ? "" :    featuredNews && featuredNews.length && featuredNews.length > 0 ?
                <div className='news-home'>
                    <div className="container-fluid">
                        <div className='row'>
                            {
                                firstcolumn.map( (item, index) =>{
                                    const { fields } = item;
                                    const image = fields["Background Image"] && fields["Background Image"].value.src;
                                    const date = fields.Date.value;
                                    const readmore = fields["Read More"].value.href;
                                    const month = new Date(date).getMonth();
                                    const year = new Date(date).getFullYear();
                                    const description = fields["Short Description"].value;
                                    const title = fields['Name'].value;

                                    return (<div className="col-md-7 col-6 left-banner" key={index}>
                                        <a  className='text-reset text-decoration-none' href={readmore}> 
                                                <div className="news-banner" style={{backgroundImage: `linear-gradient(rgba(15,38,57,0.0),rgba(15,38,57,0.0),rgba(15,38,57,0.3),rgba(15,24,34,0.9)),url(${image})`}} >
                                                    <div className='description'>   
                                                        <p className='date'>{monthNames[month] + ' ' + year}</p>
                                                        <div className="title">
                                                        <p dangerouslySetInnerHTML={{ __html: description }}></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                </a>
                                            </div>)
                                })
                            }
                        
                            <div className="col-md-5 col-6 right-banner">
                                <div className="row">
                                    {
                                        secondcolumn.map( (item,index) => {
                                            const { fields } = item; 
                                            const image = fields["Background Image"] && fields["Background Image"].value.src;
                                            const date = fields.Date.value;
                                            const readmore = fields["Read More"].value.href;
                                            const month = new Date(date).getMonth();
                                            const year = new Date(date).getFullYear();
                                            const description = fields["Short Description"].value;
                                            const title = fields['Name'].value;

                                            return  (<div className="col-md-12 col-6 pb-2 first-image-padding" key={index}>
                                                        <a  className='text-reset text-decoration-none' href={readmore}> 
                                                        <div className="news-banner" style={{backgroundImage: `linear-gradient(rgba(15,38,57,0.0),rgba(15,38,57,0.0),rgba(15,38,57,0.3),rgba(15,24,34,0.9)),url(${image})`}} >
                                                            <div className='description'>   
                                                                <p className='date'>{monthNames[month] + ' ' + year}</p>
                                                                <div className="title">
                                                                <p dangerouslySetInnerHTML={{ __html: description }}></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </a>
                                                    </div>)
                                        })
                                    } 
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            : <></>
            }
            {/* {
                 News &&  News.length && News.length > 0 ? <NewsList newslist={News} /> : <></>
            } */}
        </>
    )
}

export default News