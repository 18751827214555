import React, { useEffect } from "react";
import "./style.scss";
import $ from 'jquery';

const Policy = (props) =>{

    useEffect( () =>{

        $('.scrollup').click(function(e){
            e.preventDefault();
             var target = $($(this).attr('href'));
            if(target.length){
              var scrollTo = parseInt(target.offset().top) - parseInt(80);
              $('body, html').animate({scrollTop: scrollTo+'px'}, 800);
            }
          });
    })
    
      
    return  <div className="policy-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10 main-description" >
                            <div className={`richtext-container`} dangerouslySetInnerHTML={{__html: props && props.fields.Description && props.fields.Description.value }}></div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
              </div>
            </div>
}

export default Policy;