import React from "react";
import "./Testimonialsstyle.scss";
// import cropbuild from "../../assets/crop-build.png";
import dic from "i18next";
import Slider from "react-slick";
import left from "../../assets/left.png";
import next from "../../assets/next.png";
import CustomNextArrow from "../../library/baaShared/CustomArrow/CustomNextArrow";
import CustomPrevArrow from "../../library/baaShared/CustomArrow/CustomPrevArrow";

import JET1 from "../../assets/JET1.png";
const TestimonialsMobile = (props) => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow img={next} />,
    prevArrow: <CustomPrevArrow img={left} />,
  };

  return (
    <div className="mobile-offer-card-wrapper">
      <Slider {...settings}>
        {props.list.map((item,index) => {
          return (
            <div className="offer-card-main-wrapper" key={index}>
              <div className="offer-card-image">
                <img src={JET1} alt="dine" className="card-image" />
              </div>
              <div className="offer-card-content" id="card-content">
                <div className="offer-inner-card">
                  <div className="offer-title">{dic.t("Jclub-Testimonials")}</div>
                  <div className="offer-description">
                    <div
                      className="description pb-1"
                      dangerouslySetInnerHTML={{
                        __html: item.fields.Description.value,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default TestimonialsMobile;
