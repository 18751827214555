import React from 'react';
import dic from "i18next";
import "./ClubContact.scss";

const ClubContact = (props) => {
    const title =   props && props.fields && props.fields.Title.value;
    const Tel   =   props && props.fields && props.fields.Telephone.value;
    const Email =   props && props.fields && props.fields.Email.value;
    const MapUrl =  props && props.fields && props.fields["Location Map Url"].value.href;
    const Directionlink = props && props.fields && props.fields["Direction Url"].value.href;
    
    return (
        <div className='contact-container'>
            <div className='title'>
                <h1>{title}</h1>
            </div>
            <div className='desktop-sub-info'>
                <p><a className='text-reset'  href={`tel:${Tel}`}>T : {Tel} </a> |</p>
                <p><a className='text-reset' href={`mailto:${Email}`}>  E : {Email} </a> |</p>
                <div className='get-direction'><a className='text-reset' href={Directionlink} target="_blank"  rel="noopener noreferrer" >{dic.t("Jclub-Get Directions")}</a></div>
            </div>
            <div className='main-wrapper' id="map">
                <div className='location-map'>
                <iframe src={MapUrl} sandbox allowFullScreen="" loading="lazy" title="map"></iframe>
                </div>
            </div>
            <div className='sub-info'>
                <p><a className='text-reset'  href={`tel:${Tel}`}>T : {Tel} </a></p>
                <p><a className='text-reset' href={`mailto:${Email}`}>  E : {Email} </a></p>
                <div className='get-direction'><a className='text-reset' href={Directionlink} target="_blank"  rel="noopener noreferrer" >{dic.t("Jclub-Get Directions")}</a></div>
            </div>
        </div>
    );
}

export default ClubContact;
