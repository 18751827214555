import React from 'react';
import dic from "i18next";
import './style.scss';

const LocationsList = (props) => {
  const locationslist = props && props.fields["Images"];
  return <div className='location-slider' >
    <div className="accordion">
      <ul>
        {
          locationslist && locationslist.map((item, index) => {
            const Image = item.fields["Background Image"] && item.fields["Background Image"].value.src;
            const hotelname = item.fields["Name"] && item.fields["Name"].value;
            const hotelname1 = hotelname.slice(0,8);
            const hotelname2 = hotelname.slice(9);
            const href = item.fields["CTA"] && item.fields["CTA"].value.href;
            return (
              <li style={{ backgroundImage: `url(${Image})` }} key={index} >
                <div className='slider-parent'>
                  <a href={`${href}`}>
                    <div className='slider-description'>
                      <h2 className='title pb-3' >{hotelname1}<br/>{hotelname2}</h2>
                      <div className='discover-more-button' >
                        <button className='btn discover-more' >{dic.t("Jclub-Discover More")}</button>
                      </div>
                    </div>
                  </a>
                </div>
              </li>
            )
          })
        }
      </ul>
    </div>
  </div>
}

export default LocationsList;