import React from 'react';
import dic from "i18next";
import './MembershipOverview.scss';
import Slider from "react-slick";

const MembershipOverview = (props) => {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            autoplay:true,
            autoplaySpeed:3000,
            fade:true
        }
        const images = props && props.fields && props.fields["Carousal Images"];
        const title  = props && props.fields && props.fields["Overview Title"] && props.fields["Overview Title"].value;
        const description  =props && props.fields && props.fields["Overview Description"] && props.fields["Overview Description"].value;
        const findlink  = props && props.fields && props.fields["General CTA"] && props.fields["General CTA"].value.href;
        const booktourlink = props && props.fields && props.fields["Link"] && props.fields["Link"].value.href;
    return <div className='membership-main' > 
              <div className="membership-overview desktop-view" >
              <div className='container'>
                <div className='row m-0'>
                    <div className='col-md-7 col-lg-6 col-sm-6 col-6'>
                        <div className='membership-stage1-title'>
                            <div className='membership-main-title'>{dic.t("Jclub-Memberships")}</div>
                        </div>
                        <Slider {...settings}>
                        {
                            images.map((item, index) => {
                                const header = item.fields["Background Image"] && item.fields["Background Image"].value.src;
                                return <img src={header} className="banner-img" alt="icon-image1" key={index}/>
                                })
                        }
                        </Slider>
                    </div>
                    <div className='col-md-5 col-lg-6 col-sm-6 col-6'>
                        <div className='overview-paragraph'>
                            <div className='border-pad'>
                                <h2 className='member-title' >{title}</h2>
                                <div className='paragraph pt-3' >
                                    <p dangerouslySetInnerHTML={{__html: description }}></p>
                                </div>
                                <div className='findout-more'>
                                    <a href={findlink} target="_blank" rel="noopener noreferrer" className="text-reset text-decoration-none" ><button className="btn">{dic.t("Jclub-Membership Options")}</button></a> 
                                    <a href={booktourlink} target="_blank" rel="noopener noreferrer" className="text-reset text-decoration-none pl-2" ><button className="btn">{dic.t("Jclub-Inquire Now")}</button></a>
                            </div> 
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>

}

export default MembershipOverview;

