import React,{useEffect,useState} from 'react';
import CampaignDetail from './CampaignDetail';
import "./style.scss";


const Campaigns = (props) => {
    const featuredNews = props && props.fields && props.fields["Featured News"];
    const [getTitle,setGetTitle] = useState('');

    var urltitle = "";
    useEffect( () =>{
 
         const queryParams = new URLSearchParams(window.location.search);
         urltitle = queryParams.get('title');
         setGetTitle(urltitle);
         
    },[])

    return (
        <>
            {
            featuredNews && featuredNews.length > 0 ?                
            getTitle ?  
            <div>
                <CampaignDetail  campaignlist={featuredNews} urltitle={getTitle} />
            </div> : 
             <div className='campaign-home'>
                <div className="container-fluid">
                    <div className='row'>
                        {
                            featuredNews.map((item, index) => {
                                const image = item && item.fields && item.fields["Background Image"] && item.fields["Background Image"].value.src;
                                const description = item && item.fields && item.fields["Short Description"].value;
                                const title = item && item.fields && item.fields["Name"].value;

                                return <div className="col-md-12 col-12 left-banner" key={index} >
                                   <a href={`/en/campaigns?title=${title}`} className='text-reset text-decoration-none' >
                                        <div className="campaigns-banner" style={{ backgroundImage: `linear-gradient(rgba(15,38,57,0.0),rgba(15,38,57,0.0),rgba(15,38,57,0.3),rgba(15,24,34,0.9)),url(${image})` }} >
                                            <div className='description'>
                                                <h3 className="title">
                                                    <p dangerouslySetInnerHTML={{ __html: description }}></p>
                                                </h3>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            })

                        }

                    </div>
                </div>
            </div>
            :  ""
            }
        </>
    )
}

export default Campaigns;
