import React from 'react';
import "./Footer.scss";
import dic from "i18next";
import Newsletter from './Newsletter/Newsletter';
import { useMediaQuery } from 'react-responsive';
import NewsletterMobile from './Newsletter/NewsletterMobile';
import { FaFacebookSquare, FaInstagramSquare , FaLinkedin } from 'react-icons/fa';

const index = (props) => {
    const logo = props && props.fields && props.fields.Logo && props.fields.Logo.value.src;
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' }); 
    return (
        <div className='footer-section' >
                 { isMobile ? <div className='newsletter-mobileview'>
                         <NewsletterMobile  title={props.fields.Title.value} />
                 </div> : "" }
             <div className='container-fluid'>
                 <div className='row mobile-view-padding py-5'>
                
                     <div className='col-lg-3 col-4'>
                        <div className='footer-logo'>
                           <a href={'/en'} ><img src={logo.split('?')[0]}  alt="logo"  height={120} width={296} className='logo-image'  /> </a>
                        </div>
                     </div>

                     { !isMobile ? <div className='col-lg-4 newsletter-desktopview'   >
                         <Newsletter title={props.fields.Title.value} />
                     </div> : "" }
                    
                     <div className='col-lg-5 col-8'>
                         <div className='row pt-2'>
                            <div className='col-md-4'></div>
                            <div className='col-md-4'>
                                <ul className='footer-menuitems footer-menuitems1'>
                                    {
                                        props.fields && props.fields['Quick Links'].map((item,index) => {
                                            return <li key={index}><a  href={`${ (item.fields.Link.value.href === "") ? "#" : item.fields.Link.value.href}`}  className="text-decoration-none">{item.fields["Link Name"].value}</a></li>
                                        })
                                    }
                                </ul>
                            </div>
                            <div className='col-md-4'>
                                <ul className='footer-menuitems'>
                                    {
                                        props.fields && props.fields['Social Links'].map((item,index) => {
                                            return (
                                                item.fields["Link Name"] ? <li key={index}><a target="_blank" rel="noopener noreferrer" href={item.fields.Link.value.href} className="text-decoration-none">{item.fields["Link Name"] && item.fields["Link Name"].value}</a></li> : ''
                                            )
                                        })
                                    }
                                    <li>{dic.t("Jclub-Follow Us")}</li>
                                    <li className='icons-size social-media'>
                                    {
                                        props.fields && props.fields['Social Links'].map((item,index) => {
                                            let isfacebook = item.fields.Link.value.text === "Facebook";
                                            let isinstagram = item.fields.Link.value.text === "Instagram";
                                            let islinkdine = item.fields.Link.value.text === "Linked in";
                                            return (
                                                (isfacebook || isinstagram || islinkdine )? <a key={index} aria-label="social-links" target="_blank" rel="noopener noreferrer" href={item.fields.Link.value.href} className='tagclass pr-1' >
                                                    {isfacebook ? <FaFacebookSquare /> :  ''}
                                                    {isinstagram ? <FaInstagramSquare />: ''}
                                                    {islinkdine ? <FaLinkedin /> : ''}
                                                </a> : '' 
                                            )
                                        })
                                    }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default index;