import React, { Fragment } from "react";
import NewsArticle from "../News/NewsArticle";

const BlogDetails = (props) => {
  const { Name } = props.fields.Blogs[0].fields;
  const title = Name.value;
  console.log(props, Name);
  return (
    <Fragment>
      <div>
        {/* <div className="news-info-section"> */}
        {/* <div className="news-info-title mx-auto py-0">
          <h2>{title}</h2>
        </div> */}
        {/* </div> */}
        <NewsArticle newslist={props.fields.Blogs} urltitle={title} />
      </div>
    </Fragment>
  );
};

export default BlogDetails;
