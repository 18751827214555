"use strict";

var __assign = ( this && this.__assign ) || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var ResponseError =
/** @class */
function (_super) {

  function ResponseError(message, response) {
    var _this = _super.call(this, message) || this;

    Object.setPrototypeOf(_this, ResponseError.prototype);
    _this.response = response;
    return _this;
  }

  return ResponseError;
}(Error);

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  var error = new ResponseError(response.statusText, response);
  throw error;
} // note: encodeURIComponent is available via browser (window) or natively in node.js
// if you use another js engine for server-side rendering you may not have native encodeURIComponent
// and would then need to install a package for that functionality


function getQueryString(params) {
  return Object.keys(params).map(function (k) {
    return encodeURIComponent(k) + "=" + encodeURIComponent(params[k]);
  }).join('&');
}

function fetchData(url, fetcher, params) {
  if (params === void 0) {
    params = {};
  }
  var qs = getQueryString(params);
  var fetchUrl = url.indexOf('?') !== -1 ? url + "&" + qs : url + "?" + qs;
  //About JCLUB
  // var fetchUrl = "https://dh-dev-apim.azure-api.net/jclubsitecore/api/layout/render/jss?item=%7B96335DCB-CE5A-4A30-96A8-ED3391CE2396%7D&sc_lang=en&sc_apikey=B89355B4-C90B-424B-8273-8908031DE962";
  //Membership
  // var fetchUrl = "https://dh-dev-apim.azure-api.net/jclubsitecore/api/layout/render/jss?item=%7BAC81CFC0-C56B-4985-BB59-006ACEAD6276%7D&sc_lang=en&sc_apikey=B89355B4-C90B-424B-8273-8908031DE962";
  //Location Home
  // var fetchUrl = "https://dh-dev-apim.azure-api.net/jclubsitecore/api/layout/render/jss?item=%7B754BD478-1B15-49DF-B786-8BFBC243F5FC%7D&sc_lang=en&sc_apikey=B89355B4-C90B-424B-8273-8908031DE962";
  //JBH
  // var fetchUrl = "https://dh-dev-apim.azure-api.net/jclubsitecore/api/layout/render/jss?item=%7B5FF5A418-05E6-4F4A-8AEA-4E71522BC3BA%7D&sc_lang=en&sc_apikey=B89355B4-C90B-424B-8273-8908031DE962";
  //MAS
  // var fetchUrl = "https://dh-dev-apim.azure-api.net/jclubsitecore/api/layout/render/jss?item=%7BE5165FC5-EF2B-4BAC-BC8F-22CD1C8E4516%7D&sc_lang=en&sc_apikey=B89355B4-C90B-424B-8273-8908031DE962";
  //JET
  // var fetchUrl = "https://dh-dev-apim.azure-api.net/jclubsitecore/api/layout/render/jss?item=%7BE6ACD3DB-BC61-41A6-A1C8-FED1C3731C06%7D&sc_lang=en&sc_apikey=B89355B4-C90B-424B-8273-8908031DE962";
  //Personal Trainers Home
  // var fetchUrl = "https://dh-dev-apim.azure-api.net/jclubsitecore/api/layout/render/jss?item=%7BC256E633-E153-4581-85DA-A3770A6C6A99%7D&sc_lang=en&sc_apikey=B89355B4-C90B-424B-8273-8908031DE962";
  //Personal Trainers Profile
  // var fetchUrl = "https://dh-dev-apim.azure-api.net/jclubsitecore/api/layout/render/jss?item=%7B651CECF3-8CA1-466F-B3BC-DFAA930AF039%7D&sc_lang=en&sc_apikey=B89355B4-C90B-424B-8273-8908031DE962";
  //BookATour
  // var fetchUrl = "https://dh-dev-apim.azure-api.net/jclubsitecore/api/layout/render/jss?item=%7BCB496831-F3C7-4972-AB7E-8C620DCAB086%7D&sc_lang=en&sc_apikey=B89355B4-C90B-424B-8273-8908031DE962";
  //News
  // var fetchUrl = "https://dh-dev-apim.azure-api.net/jclubsitecore/api/layout/render/jss?item=%7B453E7D3B-6A03-4A7A-BE60-7C515BD8654D%7D&sc_lang=en&sc_apikey=B89355B4-C90B-424B-8273-8908031DE962";
  //Events
  // var fetchUrl = "https://dh-dev-apim.azure-api.net/jclubsitecore/api/layout/render/jss?item=%7B10D44FD6-E121-4E66-9261-CF3ABF2122E7%7D&sc_lang=en&sc_apikey=B89355B4-C90B-424B-8273-8908031DE962";
  //Dinning 
  // var fetchUrl = "https://dh-dev-apim.azure-api.net/jclubsitecore/api/layout/render/jss?item=%7BC119AEBA-CAE4-4730-8B32-F8F3B4C960A7%7D&sc_lang=en&sc_apikey=B89355B4-C90B-424B-8273-8908031DE962";
  // var fetchUrl = "https://servicesuat.jumeirah.com/sitecore/api/layout/render/mobile-jss?item={B96335DCB-CE5A-4A30-96A8-ED3391CE2396}&sc_apikey=B89355B4-C90B-424B-8273-8908031DE962";
  // var fetchUrl = "https://servicesuat.jumeirah.com/sitecore/api/layout/render/mobile-jss?item=%7B96335DCB-CE5A-4A30-96A8-ED3391CE2396%7D&sc_apikey=B89355B4-C90B-424B-8273-8908031DE962";
  // console.log('fetchurl',fetchUrl);
  return fetcher(fetchUrl).then(checkStatus).then(function (response) {
    // axios auto-parses JSON responses, don't need to JSON.parse
    return response.data;
  });
}

var resolveLayoutServiceUrl = function resolveLayoutServiceUrl(options, verb) {
  if (options === void 0) {
    options = {};
  }

  var _a = options.host,
      host = _a === void 0 ? '' : _a,
      _b = options.configurationName,
      configurationName = _b === void 0 ? 'jss' : _b,
      serviceUrl = options.serviceUrl;

  if (serviceUrl) {
    return serviceUrl;
  }

  return host + "/jclubsitecore/api/layout/" + verb + "/" + configurationName;
};
/**
 * Makes a request to Sitecore Layout Service for the specified route item path.
 */


function fetchRouteData(itemPath, options) {
  var querystringParams = options.querystringParams,
      layoutServiceConfig = options.layoutServiceConfig;
  var fetchUrl = resolveLayoutServiceUrl(layoutServiceConfig, 'render');
  return fetchData(fetchUrl, options.fetcher, __assign({
    item: itemPath
  }, querystringParams));
}

exports.fetchRouteData = fetchRouteData;
/**
 * Makes a request to Sitecore Layout Service for the specified placeholder in
 * a specific route item. Allows you to retrieve rendered data for individual placeholders instead of entire routes.
 */

function fetchPlaceholderData(placeholderName, itemPath, options) {
  var querystringParams = options.querystringParams,
      layoutServiceConfig = options.layoutServiceConfig;
  var fetchUrl = resolveLayoutServiceUrl(layoutServiceConfig, 'placeholder');
  return fetchData(fetchUrl, options.fetcher, __assign({
    placeholderName: placeholderName,
    item: itemPath
  }, querystringParams));
}

exports.fetchPlaceholderData = fetchPlaceholderData;