import React, { Fragment } from "react";
import "./style.scss";
import EventGallery from "../EventGallery/index";
import dic from "i18next";
const EventDetail = (props) => {
  const { eventlist, urltitle } = props;
  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const post = eventlist.filter((result) => {
    return result.fields["Name"].value === urltitle;
  });

  return (
    <Fragment>
      <div className="event-detail-main-container">
        {post.map((post, key) => {
          const title = post && post["fields"] && post.fields["Name"].value;
          const Description =
            post && post["fields"] && post.fields["Description"].value;
          const image =
            post && post["fields"] && post.fields["Background Image"].value.src;
          const Dates = post && post["fields"] && post.fields["Date"].value;
          const Locationhref =
            post &&
            post["fields"] &&
            post.fields["CTA"].value &&
            post.fields["CTA"].value.href;
          const LocationTitle =
            post &&
            post["fields"] &&
            post.fields["CTA"].value &&
            post.fields["CTA"].value.text;
          return (
            <div className="row p-0 m-0" key={key}>
              <div
                className="col-md-6 left-background-image"
                style={{ backgroundImage: `url(${image})` }}
              ></div>
              <div className="col-md-6 col-12 right-background-event">
                <div className="event-title">
                  <p>{title}</p>
                </div>
                <div className="show-mobile-detail">
                  <p>
                    DATE : {new Date(Dates).getDate()}{" "}
                    {monthNames[new Date(Dates).getMonth()]}{" "}
                    {new Date(Dates).getFullYear()}
                  </p>
                </div>
                <div className="show-mobile-location">
                  <p>{LocationTitle}</p>
                </div>
                <div className="event-location-time">
                  <p>
                    Location:{" "}
                    <span>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={Locationhref}
                        className="text-reset"
                      >
                        {LocationTitle}
                      </a>
                    </span>
                  </p>
                  <p>
                    Date: {new Date(Dates).getDate()}{" "}
                    {monthNames[new Date(Dates).getMonth()]}{" "}
                    {new Date(Dates).getFullYear()}
                  </p>
                  {/* <p>Date : 14 dec 2021 19:30 to 21:00</p> */}
                </div>
                <div className="event-description">
                  <div dangerouslySetInnerHTML={{ __html: Description }}></div>
                </div>

                <div className="booknow-button d-none">
                  <button className="btn">
                    {dic.t("Jclub-Book Now", "Book Now")}
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <EventGallery posts={post} />
    </Fragment>
  );
};

export default EventDetail;
