import React, { useRef } from "react";
import "./NewsMobileArticle.scss";
import Slider from "react-slick";
import left from "../../../assets/navy-left-arrow.png";
import right from "../../../assets/navy-right-arrow.png";

const NewsMobileArticle = (props) => {
  const { newslist, urltitle } = props;

  const sliderRef = useRef();

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: true,
    // autoplay:true,
    // autoplaySpeed:20000,
    fade: true,
  };

  var keytrigger = 0;
  newslist.filter((result, key) => {
    if (result.fields["Name"].value === urltitle) {
      keytrigger = key;
    }
    return result.fields["Name"].value === urltitle;
  });

  const gotoNext = () => {
    sliderRef.current.slickNext();
  };

  const gotoPrev = () => {
    sliderRef && sliderRef.current && sliderRef.current.slickPrev();
  };
  const initialSlides = (keytrigger) => {
    sliderRef && sliderRef.current && sliderRef.current.slickGoTo(keytrigger);
  };

  setTimeout(initialSlides);

  return (
    <div className="news-mobile-article">
      <Slider {...settings} ref={sliderRef}>
        {newslist.map((post, index) => {
          const { fields } = post;
          const title = fields["Name"].value;
          const short_description = fields["Description"].value;
          const src = fields["Background Image"].value.src;

          return (
            <div className="slider-news" key={index}>
              {/* <div className="image">
                                    <img src={src} className="img-fluid" alt={`news-article-${index}`} />
                                </div> */}
              <div className="contents">
                <p className="title">{title}</p>
                <div className="description">
                  <p
                    dangerouslySetInnerHTML={{ __html: short_description }}
                  ></p>
                </div>
              </div>
            </div>
          );
        })}

        {/*                   
                    <div className="slider-news" >
                        <div className="image">
                            <img src={news1} className="img-fluid" alt="news-article-image" />
                        </div>
                        <div className="contents">
                            <p className="title" > THE YEAR” ACCORDING TO <br /> FIT AWARDS MIDDLE EAST 2022</p>
                            <div className="description"> 
                                <p>United Arab Emirates, Dubai, 23rd February 2022: J Club, Dubai’s pre-eminent lifestyle and wellness club has been recently awarded “Best Facility of the Year” during the 8th edition of the prestigious Fit Awards Middle East, Large Clubs’ category with 1000+ members. Desmond Cawley, Director of Health and Fitness at J Club has received the award during the 2022 FIT Awards ceremony which took place on February 15, at Soho Garden, Dubai.</p>
                                <p>Organized by Sports 360, Reps Middle East, and supported by Dubai Sports Council, the 2022 Fit Awards Middle East is the only award of its kind in the region, with a total of 20 categories, rewarding those in various business disciplines and individuals who are making a difference. </p>
                                <p>Located at the luxurious resorts of Jumeirah Beach Hotel, Jumeirah Mina A’Salam, and Jumeirah Emirates Towers hotel, J Club’s exclusive memberships combine world-class hospitality, the latest innovations in sports and leisure facilities, and personalized, thoughtful service for a lifestyle experience that encompasses mind, body, and soul. With a knowledgeable team of experts, including certified personal trainers, endurance coaches, and wellness professionals, J Club combines the best of fitness, leisure, spa, and wellness, offering its members a holistic, curated, and personalized experience that inspires them to keep moving forward.</p>
                                <p>The fitness industry in the Middle East comprises of more than 15,000 registered professionals across more than 500 venues. As the industry grows and as a way to validate the excellence of professionals in the region, Fit awards recognizes and rewards those who have made this industry a success across all the diverse health & fitness segments. As part of the shortlisting process, applicants were evaluated on excellence in areas such as member recruitment and retention, staff training and expertise, customer satisfaction, services provided, marketing, USP and more. Finalists were then evaluated by a panel of judges who discussed the findings and placed their final votes.</p>    
                                <p>J Club offers a unique blend of state-of-the-art personalized wellness services and fitness facilities with 11 tennis courts and outdoor gyms using latest high-tech equipment. The club has partnered with world class partners in all aspects, including Patrick Mouratoglou for The Mouratoglou Tennis Academy; Huna Aquatics Swimming; Elite Sports Performance taking care of its Elite Sports Performance Centre; and squash where they are partnered with professional coaches.</p>
                            </div>
                        </div>
                    </div>      
                */}
      </Slider>
      <div className="arrow-settings py-4">
        <span className="left" onClick={gotoPrev}>
          <img src={left} className="img-fluid left-arrow" alt="left-arrow" />
          Previous Story
        </span>
        <span className="right" onClick={gotoNext}>
          Next Story
          <img
            src={right}
            className="img-fluid right-arrow"
            alt="right-arrow"
          />
        </span>
      </div>
      <br />
    </div>
  );
};

export default NewsMobileArticle;
