import React from 'react';
import "./Accordian.scss";
import { BsChevronDown } from "react-icons/bs";

const Accordion = ({
    showDescription,
    ariaExpanded,
    fontWeightBold,
    item,
    index,
    onClick,
    info,
}) => {
    const Title = item.fields && item.fields.Title.value;
    return (<>
        <div className="member" key={item.title}>
            <dt>
                <button
                    aria-expanded={ariaExpanded}
                    aria-controls={`faq${index + 1}_desc`}
                    data-qa="faq__question-button"
                    className={`member-button ${fontWeightBold}`}
                    onClick={onClick}
                >
                    <div className='Button-Title'>{item.fields && item.fields.Title.value}</div>
                    <span className={`${fontWeightBold ? 'arrow-icon-up':'arrow-icon-down'}`}><BsChevronDown/></span>
                </button>
            </dt>
            <dd>
                <div
                    id={`faq${index + 1}_desc`}
                    data-qa="faq__desc"
                    className={`member-desc ${showDescription}`}
                >
                    {item.fields && item.fields["Comparison Lists"] && item.fields["Comparison Lists"].map((item, index) => {
                        const DeatilList = item && item.fields["Membership Comparison Details List"];
                        return (
                            // <div className={`member-item ${ (index%2 === 0) ? 'even-item':'odd-item'}`} key={index}>
                            //     <div className={"member-item-title"} >{item.fields.Title.value}</div>
                            //     <div className={"member-item-list"}>
                            //         <div className={`${item.fields.Lifestyle.value === true ? 'circle-fill' : 'circle-empty'}`}></div>
                            //         <div className={`${item.fields.Fitness.value === true ? 'circle-fill' : 'circle-empty'}`}></div>
                            //         <div className={`${item.fields.City.value === true ? 'circle-fill' : 'circle-empty'}`}></div>
                            //     </div>
                            // </div>
                            <>
                                <div className='item-gradient'>
                                    <h3 className='item-gradient-title'>{item.fields.Title.value}</h3>
                                    {
                                        DeatilList && DeatilList.map((item,index) => {
                                            let DetailTitle = item.fields && item.fields.Title && item.fields.Title.value;
                                            let valuetobecheck = Title.toLowerCase().charAt(0).toUpperCase() + Title.toLowerCase().slice(1);
                                            const IsPresent = item.fields && item.fields[`${valuetobecheck}`] && item.fields[`${valuetobecheck}`].value;
                                            return(
                                                 IsPresent && <p className='item-gradient-list-title'>{DetailTitle}</p>
                                            )
                                        })
                                    }
                                </div>
                               
                            </>
                        )
                    })}
                     <p className='star-info'>{info}</p>
                </div>
            </dd>
        </div>
    </>);
}

export default Accordion;