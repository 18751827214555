import React, { useState } from "react";
import header from "../../assets/header.jpg";
import { FaFacebookSquare, FaInstagramSquare,FaLinkedin } from "react-icons/fa";
import "./burgerStyle.scss";
import { useMediaQuery } from 'react-responsive';

const BurgerMenu = (props) => {
  const [Location, setLocation] = useState(false);
  const [Timetable, setTimetable] = useState(false);
  const [MediaCenter, setMediaCenter] = useState(false);
  const [navigationStatus,setnavigationStatus] = useState(true);
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1200px)'})

  const handleLocation = () => {
      setTimetable(false);
      setLocation(!Location);
      setMediaCenter(false);
  };

  const handleTimetable = () => {
      setTimetable(!Timetable);
      setLocation(false);
      setMediaCenter(false);
  };

  const handleMediaCenter = () => {
      setTimetable(false);
      setLocation(false);
      setMediaCenter(!MediaCenter);
  }

  const handlenavigationStatus = () =>{
    setnavigationStatus(!navigationStatus);
    if(navigationStatus === true){
      document.getElementsByTagName("body")[0].style = "overflow-y:hidden";
    }else{
      document.getElementsByTagName("body")[0].style = "overflow-y:scroll";
    }
  }

 
  
  
  return (
    <div className="navigation navigation-main pb-3">
      <input type="checkbox" className="navigation__checkbox" id="nav-toggle" />
      <label htmlFor="nav-toggle" className="navigation__button" onClick={handlenavigationStatus}>
        <span className="navigation__icon" ></span>
      </label>
      <div
        className="navigation__nav"
        style={{
          backgroundImage: `linear-gradient(rgb(1 12 21 / 92%), rgb(1 12 21 / 92%)), url(${header})`,
        }}
      >
        <div className="row p-0 m-0">
          <div className="position-absolute">
          <a href={'/en'}> 
            <img src={props.logo.split('?')[0]} alt="logo" height={296} width={296} className="logo-image m-0" />
          </a>
           </div>
          <div className="col-md-12 col-lg-12 margin-4 text-center">
            <ul className="navigation__list p-0">
              <li className="navigation__item">
                <ul className="p-0">
                  {props.navigation.map((item,index) => {
                    const href = item.fields["Direct CTA Link"] && item.fields["Direct CTA Link"].value.href;
                    const showheader = item.fields["Show In Header"] && item.fields["Show In Header"].value;
                    const islocation =
                      item.fields["Page Title"].value === "Locations";
                    const isTimetable =
                      item.fields["Page Title"].value === "TimeTables";
                    const isMediaCenter =
                      item.fields["Page Title"].value === "Media Center";
                    return isTimetable ? (
                      <li key={index}>
                        <a
                          className={`dropdown-toggle ${
                            Timetable
                              ? "dropdown-toggle-up"
                              : "dropdown-toggle-down"
                          } atag`}
                          onClick={handleTimetable}
                        >
                          {item.fields["Page Title"].value}
                        </a>
                        {Timetable ? (
                          <ul className="dropdown-menus p-0">
                            {item.fields["Secondary Navigations"].map(
                              (item, index) => {
                                const href = item.fields && item.fields["Link"] && item.fields["Link"].value.href;
                                return (
                                  <a target="_blank" rel="noopener noreferrer" href={href} key={index}>
                                  <li
                                    data-aos={`${
                                      index % 2 === 0
                                        ? "fade-right"
                                        : "fade-left"
                                    }`}
                                    data-aos-duration="600"
                                  >
                                    {item.fields["Link Name"].value}
                                  </li>
                                  </a>
                                );
                              }
                            )}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    ) : (
                      isDesktopOrLaptop && showheader === true ? "":  <li key={index}>
                      
                      {
                            href ? 
                            <a href={`${href}`} className="atag">
                            {item.fields["Page Title"].value}
                          </a>
                        : ""
                        
                      }
                    
                    
                    </li>
                    );
                  })}
                  {props.links.map((item,index) => {
                    const href = item.fields["Direct CTA Link"] && item.fields["Direct CTA Link"].value.href;
                    return (
                      href ? 
                      <li key={index}>
                        <a href={`${ (href === undefined) ? '#' : href}`} className="atag">
                          {item.fields["Link Name"].value}
                        </a>
                      </li>
                      : ""
                    );
                  })}
                </ul>
              </li>
            </ul>
          </div>
          <div className="social-burger">
          {
              props.social_links.map((item,index) => {
                  let isfacebook = item.fields.Link.value.text === "Facebook";
                  let isinstagram = item.fields.Link.value.text === "Instagram";
                  let islinkdine = item.fields.Link.value.text === "Linked in";
                  return (
                      (isfacebook || isinstagram || islinkdine )? <a key={index} target="_blank" rel="noopener noreferrer" href={item.fields.Link.value.href} className='text-reset' >&nbsp;
                          {isfacebook ? <FaFacebookSquare /> :  ''}
                          {isinstagram ? <FaInstagramSquare />: ''}
                          {islinkdine ? <FaLinkedin /> : ''}
                      </a> : '' 
                  )
              })
          }
          </div>
        </div>
      </div>
    </div>
  );
};

export default BurgerMenu;
