import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import Helmet from 'react-helmet';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/slick-carousel/slick/slick-theme.css';
import '../node_modules/slick-carousel/slick/slick.css';
import './assets/app.css';
// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.
/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/



const Layout = ({ route , context }) => (
  <React.Fragment>
    {/* react-helmet enables setting <head> contents, like title and OG meta tags */}

    <Helmet>
      <title>{route.fields['Meta Title'] ? route.fields['Meta Title'].value : ""}</title>
      <meta name="description" content={ route.fields['Meta Description'] && route.fields['Meta Description'].value ? route.fields['Meta Description'].value : ""}>
      </meta>
      <link rel="canonical" href={route.fields['Direct CTA Link'] && route.fields['Direct CTA Link'].value && route.fields['Direct CTA Link'].value.href ? route.fields['Direct CTA Link'].value.href :""}>
      </link>
      {/* <meta name="keywords" content={route.fields['Meta Keywords'].value ? route.fields['Meta Keywords'].value : ""}>
      </meta>
      <meta name="title" content={route.fields['Meta Title'] ? route.fields['Meta Title'].value : ""}>
      </meta> */}
    </Helmet>

    {/* <div className={"Page-layout"+ (route.fields.Title.value !== "Home" ? ' not-homepage':' homepage')}>
    <Placeholder name="hcjss-header" rendering={route} />
	  <Placeholder name="hcjss-main" rendering={route} />
	  <Placeholder name="hcjss-footer" rendering={route} />
    </div> */}

    <div>
      <Placeholder name="Header" rendering={route} />
      <Placeholder name="HeroBanner" rendering={route} />
      <Placeholder name="Main" rendering={route} />
      <Placeholder name="Footer" rendering={route} />
    </div>
  </React.Fragment>
);

export default Layout;
