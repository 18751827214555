import React from "react";
import "./style.scss";
import Slider from "react-slick";
import dic from "i18next";
const UpcomingEvents = (props) => {
  const Events = props.events;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1.05,
    slidesToScroll: 1,
    arrows: false,
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const renderEventItem = (item, index) => {
    const title =
      item &&
      item["fields"] &&
      item.fields["Name"] &&
      item.fields["Name"].value;
    const shortDescription =
      item &&
      item["fields"] &&
      item.fields["Short Description"] &&
      item.fields["Short Description"].value;
    const image =
      item &&
      item["fields"] &&
      item.fields["Background Image"] &&
      item.fields["Background Image"].value &&
      item.fields["Background Image"].value.src;
    const Dates =
      item &&
      item["fields"] &&
      item.fields["Date"] &&
      item.fields["Date"].value;
    const bookingAvailableStatus =
      item &&
      item.fields["Booking Available"] &&
      item.fields["Booking Available"].value;
    const bookNowLink =
      item &&
      item.fields["Book Now CTA"] &&
      item.fields["Book Now CTA"].value &&
      item.fields["Book Now CTA"].value.href;
    const ReadMore =
      item &&
      item.fields["Read More"] &&
      item.fields["Read More"].value &&
      item.fields["Read More"].value.href;
    const currentDate = new Date(Dates);
    const finalTitle = title.slice(0, 138) + (title.length > 100 ? "..." : "");
    const showDate =
      monthNames[currentDate.getMonth()] + " " + currentDate.getDate();
    const description = shortDescription || "";
    const mobileFinalDescription =
      description.slice(0, 80) + (description.length > 138 ? "..." : "");
    const desktopFinalDescription =
      description.slice(0, 138) + (description.length > 138 ? "..." : "");

    return (
      <div
      className="upcoming-events-slider col-12 col-sm-4 col-md-6 col-lg-6 col-xl-4 col-xll-4"
      key={index}
    >
      <div className="profile classy-card">
        <a
          href={bookingAvailableStatus ? "javascript:;" : ReadMore}
          className={
            bookingAvailableStatus
              ? "text-reset text-decoration-none cursor-none"
              : "text-reset text-decoration-none"
          }
        >
          <div
            className="events-bg-image"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
          <div className="card-body">
            <p className="title">{finalTitle}</p>
            {description && (
              <div className="description mobile_final_description">
                <div
                  className="blog-title"
                  dangerouslySetInnerHTML={{ __html: description }}
                ></div>
              </div>
            )}
            {description && (
              <div className="description desktop_final_description">
                <div dangerouslySetInnerHTML={{ __html: description }}></div>
              </div>
            )}
            <div className="events-showdate d-none">{showDate}</div>
            <div className="main-link">
              {bookingAvailableStatus && (
                <div className="book-link">
                  <a
                    href={bookNowLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-reset text-decoration-none"
                  >
                    <p>{dic.t("Jclub-Book Now", "Book Now")}</p>
                  </a>
                </div>
              )}
              <div
                className={
                  bookingAvailableStatus ? "info-link-single" : "info-link"
                }
              >
                <a href={ReadMore} className="text-reset text-decoration-none">
                  <p>{dic.t("Jclub-Info", "INFO")}</p>
                </a>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    
    );
  };

  return (
    <div className="container-fluid events-main-container">
      <div className="events-title">
        <h2>{props.title}</h2>
      </div>
      <div className={`upcoming-events row`}>
        {Events.map((item, index) => renderEventItem(item, index))}
      </div>
    </div>
  );
};

export default UpcomingEvents;
