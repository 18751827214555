
import React from 'react'
// import PrevArrow from './../../../assets/images/icons/white.png';
import './CustomArrow.scss'
export default function customPrevArrow(props) {
    // hide the arrow if it is first one
    if (!props.onClick) {
        return null;
    }
    return (
        <div className={props.className} onClick={props.onClick} >
            <img src={props.img} alt="left arrow" />
        </div>
    )
}
