import React, { useRef } from 'react'
import "./OtherClubs.scss";
import dic from "i18next";
import Slider from 'react-slick';
import next from "../../assets/next.png";
import CustomNextArrow from "../../library/baaShared/CustomArrow/CustomNextArrow";
import CustomPrevArrow from "../../library/baaShared/CustomArrow/CustomPrevArrow";

const OtherClubs = (props) => {

    const sliderRef = useRef();
    const clubs = props && props.fields && props.fields.Offers;
    const clubtitle = props && props.fields && props.fields.Title && props.fields.Title.value ;
    const settings = {
        dots: false,
        arrows: false,
        speed: 500,
        slidesToShow: clubs.length >1 ? 2:1,
        slidesToScroll: 1,
        nextArrow: <CustomNextArrow img={next} />,
        prevArrow: <CustomPrevArrow img={next} />,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                  slidesToShow: clubs.length >1 ? 2:1,
                  slidesToScroll: clubs.length >1 ? 2:1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
            },
          ],        
      };

      
    const gotoNext = () => {
        sliderRef.current.slickNext();
        sliderRef.current.slickGoTo(0);
    };

    const gotoPrev = () => {
        sliderRef.current.slickPrev();
    };

   
    return (
        <>
            <div className='otherclub-container'>
                <h2 className='otherclubs-title'>{clubtitle}</h2>
                <div className='otherclubs-overview'>
                        <Slider {...settings} ref={sliderRef} >
                            {
                                clubs && clubs.map((item, index) => {
                                    const image = item && item.fields && item.fields["Background Images"][0].fields["Background Image"].value.src;
                                    const title = item && item.fields && item.fields.Title.value;
                                    const description = item && item.fields && item.fields["Short Description"].value;
                                    const href = item && item.fields && item.fields["Background Images"][0].fields["CTA"].value.href;
                                    return (
                                        <div className="hotel-card" key={index} style={{display:"flex"}}>
                                        <a href={href} className="text-reset text-decoration-none" >
                                                <div className='top-div'>
                                                    <div className='middle'>
                                                        <img src={image} alt="other-club" className='club-image' />
                                                    </div>
                                                </div>
                                                <div className='bottom-div'>
                                                    <h3 className='hotel-title'>
                                                        {title}
                                                    </h3>
                                                    <div className='hotel-description'>
                                                    <div dangerouslySetInnerHTML={{ __html: description }}></div>
                                                    </div>
                                                    <div className='link'>
                                                        <p className='learnmore'>{dic.t("Jclub-Learn More")}</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                        <div className="arrow-settings-otherclub">
                            <div  className='container-fluid' >
                               <div className='row common-row-relative ' > 
                                 <div className='col-6 common-relative text-left' > 
                                    <img
                                    src={next}
                                    alt="left-arrow"
                                    onClick={gotoPrev}
                                    className="img-fluid  left-arrow"
                                    />
                                </div>   
                                <div className='col-6  common-relative text-right' > 
                                    <img
                                    src={next}
                                    alt="right-arrow"
                                    onClick={gotoNext}
                                    className="img-fluid right-arrow"
                                    />
                                </div>   
                             </div> 
                           </div>
                         </div>
                </div>
            </div>
        </>
    )
}

export default OtherClubs