import React from 'react';
import './style.scss';
import Accordion from './Accordian';
import dic from "i18next";

const JClubFAQ = (props) => {
  const image = props.fields["Background Image"] && props.fields["Background Image"].value.src;
  const intialList = props.fields["FAQs"];
  return(
    <div>
      <div className='jclub-faq-container'>
        {/* <div className="linear-gradient-container" /> */}
        <div className='jclub-faq-top-container'>
          <h1 className='faq-title' data-aos="fade-up" data-aos-duration="1200" >{`${dic.t("JClub")} ${props.fields["Title"].value}`}</h1>
        </div>
        <Accordion faqList={intialList} />
      </div>
    </div>
  )
};

export default JClubFAQ;
