import React from "react";
import './sitemap.scss';

const Sitemap = (props) => {
  const current_location_name = typeof window !== 'undefined' && window.location.pathname;
  return (
    <div className="sitemap-container">
      <div className="title-container">
        <a href={current_location_name} className="title">
          JClub Site Map
        </a>
      </div>
      <div className="grid-container">
        <div className="grid-item">
          <a href="/en" className="list-title">
            Home
          </a>
        </div>
        <div className="grid-item">
          <a href="/en/memberships" className="list-title">
            Memberships
          </a>
        </div>
        <div className="grid-item">
          <a href="/en/events" className="list-title">
            Events & Offers
          </a>
        </div>
        <div className="grid-item">
          <a href="/en/locations" className="list-title">
            Locations
          </a>
          <ul className="list-wrapper">
            <li className="list-indiviual">
              <a href="/en/hotels/jumeirah-beach-hotel">Jumeirah Beach Hotel</a>
            </li>
            <li className="list-indiviual">
              <a href="/en/hotels/jumeirah-emirates-towers">
                Jumeirah Emirates Towers
              </a>
            </li>
            <li className="list-indiviual">
              <a href="/en/hotels/jumeirah-mina-a-salam">
                Jumeirah Mina Asalam
              </a>
            </li>
          </ul>
        </div>
        <div className="grid-item">
          <a href="/en/personal-trainers" className="list-title">
            Personal Trainers
          </a>
          <ul className="list-wrapper">
            <li className="list-indiviual">
              <a href="/en/personal-trainer-profiles/alisher-toymuradov">
                Alisher Toymuradov
              </a>
            </li>
            <li className="list-indiviual">
              <a href="/en/personal-trainer-profiles/anthony-okpala">
                Anthony Okpala
              </a>
            </li>
            <li className="list-indiviual">
              <a href="/en/personal-trainer-profiles/anton-matlakhov">
                Anton Matlakhov
              </a>
            </li>
            <li className="list-indiviual">
              <a href="/en/personal-trainer-profiles/ksenia">Ksenia</a>
            </li>
            <li className="list-indiviual">
              <a href="/en/personal-trainer-profiles/mira-usonkanova">
                Mira Usonkanova
              </a>
            </li>
            <li className="list-indiviual">
              <a href="/en/personal-trainer-profiles/mehrdad">Mehrdad</a>
            </li>
            <li className="list-indiviual">
              <a href="/en/personal-trainer-profiles/eric-muriungi">
                Eric Muriungi
              </a>
            </li>
            <li className="list-indiviual">
              <a href="/en/personal-trainer-profiles/ebrima-jarra">
                Ebrima Jarra
              </a>
            </li>
            <li className="list-indiviual">
              <a href="/en/personal-trainer-profiles/larry-adebayo-adebola">
                Larry
              </a>
            </li>
          </ul>
        </div>
        <div className="grid-item">
          <a href="/en/book-a-tour" className="list-title">
            Book A Tour
          </a>
        </div>
        <div className="grid-item">
          <a href="/en/news" className="list-title">
            News
          </a>
        </div>
        <div className="grid-item">
          <a href="/en/dining" className="list-title">
            Dinning
          </a>
        </div>
        <div className="grid-item">
          <a href="/en/contact-us" className="list-title">
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sitemap;
