import React, { useRef } from "react";
import JET1 from "../../assets/JET1.png";
import "./style.scss";
import dic from "i18next";
import Slider from "react-slick";
import TestimonialsMobile from "./TestimonialsMobile";
import left from "../../assets/left.png";
import right from "../../assets/next.png";

const Testimonials = (props) => {
  const sliderRef = useRef();

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 20000,
    fade: true,
  };

  const gotoNext = () => {
    sliderRef.current.slickNext();
    sliderRef.current.slickGoTo(0);
  };

  const gotoPrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div >
      {
        props.list && props.list.length > 0 ?
          <div className="testimonial-main">
             <div className="mobile-view">
              <TestimonialsMobile list={props.list} />
            </div>
            <div className="testimonial-overview desktop-view py-5">
              <div className="container">
                <div className="row m-0">
                  <div className="col-md-6 col-lg-6 col-sm-6 col-6">
                    <div className="testimonial-stage1-title">
                      <div className="testimonial-main-title">{dic.t("Jclub-Testimonials")}</div>
                    </div>

                    <div
                      className="background-img"
                      style={{ backgroundImage: `url(${JET1})` }}
                    >
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-6 col-6 testimonial-overview-paragraph">
                    {
                      props.list && props.list.length > 0 ?
                        <div className="overview-paragraph">
                          <div className="border-pad">
                            <h4 className="member-title">{dic.t("Jclub-Testimonials")}</h4>
                            <Slider {...settings} ref={sliderRef}>
                              {
                                props.list.map((item, index) => {
                                  return (
                                    <div className="paragraph pt-3" key={index}
                                      dangerouslySetInnerHTML={{
                                        __html: item.fields.Description.value,
                                      }}
                                    ></div>
                                  );
                                })}
                            </Slider>
                            {
                              props.list.length !== 1 ?

                                <div className="arrow-settings pt-3 text-right">
                                  <img
                                    src={left}
                                    onClick={gotoPrev}
                                    alt='left'
                                    className="img-fluid left-arrow"
                                  />
                                  <img
                                    src={right}
                                    onClick={gotoNext}
                                    alt='right'
                                    className="img-fluid right-arrow"
                                  />
                                </div>

                                : ""
                            }



                            <br />
                          </div>
                        </div>

                        : ""
                    }


                  </div>
                </div>
              </div>
            </div>
          </div> : ""
      }

    </div>
  );
};

export default Testimonials;
