import React,{Fragment, useEffect,useState} from 'react';
import './style.scss';
import Slider from 'react-slick';
import dic from "i18next";
import CustomNextArrow from "../../library/baaShared/CustomArrow/CustomNextArrow";
import CustomPrevArrow from "../../library/baaShared/CustomArrow/CustomPrevArrow";
import left from "../../assets/leftgrey-arrow.png";
import EventDetail from '../EventDetail/index';
import UpcomingEvents from '../UpcomingEvents';

const EventsList = (props) =>{

  const Events = props && props.fields && props.fields["Previous Events"];
  const title = props && props.fields && props.fields.Title.value;
  const UpcomingEventsData  = props && props.fields && props.fields["Upcoming Events"];

  var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const [getTitle,setGetTitle] = useState('');
  const [gettemparray,setGetTempArray] = useState(Events);
  const [eventType,setEventType] = useState('');
  const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        nextArrow: <CustomNextArrow img={left} />,
        prevArrow: <CustomPrevArrow img={left} />,
        responsive: [
            {
              breakpoint: 1500,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
              },
            },
            {
              breakpoint: 1100,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
            {
                breakpoint: 900,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 2,
                },
            },
          ],        
      };

      var urltitle = "";
      var type = "";
      useEffect( () =>{
   
           const queryParams = new URLSearchParams(window.location.search);
           urltitle = queryParams.get('title');
           type = queryParams.get('type');
           setGetTitle(urltitle);
           setEventType(type);
           
      },[])

      var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      var today = new Date(), d, month, year;
      var lastoneyear = [];
      for(var i = 10; i > 0; i -= 1) {
           d = new Date(today.getFullYear(), today.getMonth() - i, 1);
           month = monthNames[d.getMonth()];
           year = d.getFullYear();
           lastoneyear.push(month+' '+year);
      }
      lastoneyear.reverse();


      const getDatePreviousEvents = (e) =>{
        
        if(e.target.value === "older-selected"){
          setGetTempArray(Events);
        }else{

            const temparray = [];
            var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const monthname = e.target.value;
            const newlist = Events.filter( (result,key) => { 

                if(monthNames[new Date(result.fields['Date'].value).getMonth()]+' '+new Date(result.fields['Date'].value).getFullYear() === monthname){
                    temparray.push(result);
                } 

            });
            setGetTempArray(temparray);
        }
      }
      
    return <Fragment>
      {
      getTitle ?  <EventDetail  eventlist={eventType === "previous" ? Events  : UpcomingEventsData } urltitle={getTitle}     /> :
      <>
      {
         UpcomingEventsData && UpcomingEventsData.length > 0 ?  <UpcomingEvents events={UpcomingEventsData} urltitle={getTitle}  title={title} /> : ""
      }
      <div className='container-fluid previous-events-main-container' >
      <div className="previous-events-filter"> 
            <div className='desktop-events-filter' >
                <p className="title">PREVIOUS EVENTS</p>
                <div className="filters">
                      <div className="row">
                          <div className="col-md-2 col-2 col-lg-3 col-sm-3"></div>
                          <div className="col-md-8 col-8 col-lg-6 col-sm-6 text-center mobile-filter-adjust-event">
                              <span className="filter-label" >Filter By Date: </span>
                                  &nbsp; <select className="form-control " onChange={getDatePreviousEvents} >
                                            <option value={'older-selected'}>SHOW ALL</option>
                                                {
                                                      lastoneyear.map( (result,index) =>{
                                                        return <option value={result} key={index}>{result}</option>
                                                    })
                                                }
                                            </select>
                          </div>
                          <div className="col-md-2 col-2 col-lg-3 col-sm-3"></div>
                      </div>
                </div>  
           </div>
           <div className='mobile-previous-events' >   
              <p className="title">PREVIOUS EVENTS</p>
              <p className="filter-label" >Filter by date:</p>
              <select className="form-control select-date" onChange={getDatePreviousEvents}  > 
                      <option value={'older-selected'}>SHOW ALL</option>
                      {
                            lastoneyear.map( (result,index) =>{
                              return <option value={result} key={index}>{result}</option>
                          })
                      }
                </select>
          </div>
       </div>
       <div className='previous-all-events row'>
              {
               gettemparray.length ?
                gettemparray.map((item,index) =>{
                  const title = item && item['fields'] && item.fields['Name'].value;
                  const short_description = item && item['fields'] && item.fields["Short Description"].value;
                  const image = item && item['fields'] && item.fields["Background Image"] && item.fields["Background Image"].value.src;
                  const Dates = item && item['fields'] && item.fields['Date'].value;
                  const ReadMore = item.fields["Read More"].value.href;
                  const currentdate = new Date(Dates);
                  const showdate = monthNames[currentdate.getMonth()] +' '+currentdate.getDate();
                  const description = short_description;
                  var mobile_final_description = description.slice(0, 80) + (description.length > 35 ? "..." : "");
                  var desktop_final_description = description.slice(0, 108) + (description.length > 35 ? "..." : "");

                    return <div className="col-6  col-sm-4 col-md-6 col-lg-6 col-xl-4 col-xll-4  previous-all-events-slider" key={index}>
                      <div className="profile">
                      <a href={ReadMore} className="text-reset text-decoration-none" >
                          <div className="events-bg-image" style={{backgroundImage: `url(${image})`}} ></div>
                          <div className="card-body">
                            <p className="title">{title}</p>
                            <p className='border-bottom'></p>
                            <p className="mobile-title">{title}</p>
                           {mobile_final_description &&  <div className="description mobile_final_description"><div dangerouslySetInnerHTML={{ __html: mobile_final_description }}></div></div>}
                            {desktop_final_description && <div className='description desktop_final_description' ><div dangerouslySetInnerHTML={{ __html: desktop_final_description }}></div></div>}
                            <div className="events-showdate d-none">{showdate}</div>
                            <div className='main-link'> 
                              <div className='info-link' >
                                    <p   className="text-reset text-decoration-none" ><span>{dic.t("Jclub-Read more")}</span></p>
                                </div>
                            </div>
                            </div>
                            </a>
                        </div>
                      </div>
                    })
                  : <p className='text-center no-filter-date' > No past events in your filtered date </p>
              } 
        </div>
  </div>
  </>
    }
           </Fragment>

}

export default EventsList;