import React, { Fragment } from 'react'
import "./CampaignDetailStyle.scss";
import CampaignMobile from './CampaignMobile';
import './CampaignMobileStyle.scss';

const CampaignDetail = (props) => {

    const { campaignlist, urltitle } = props;

    const post = campaignlist.filter((result) =>{
       return result.fields['Name'].value === urltitle;
    });
    
    return (
        <>
            <CampaignMobile campaignlist={post} urltitle={urltitle}  />
            <div className='article-campaign'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='row flex-column mx-0 mt-2'>
                           {
                               post.map( (post,index)=> {
                                const title = post && post['fields'] && post.fields['Short Description'].value;
                                const Description = post && post['fields'] && post.fields["Description"].value;
                                const src = post && post['fields'] && post.fields["Background Image"].value.src;
                                    return<Fragment key={index}>  
                                        <h2 className='article-title text-muted'> <span dangerouslySetInnerHTML={{ __html: title }}></span></h2>
                                          <img className='article-image' alt={`news-${index}`} src={src} />
                                       <div className='article-description'>
                                          <p dangerouslySetInnerHTML={{ __html: Description }}></p>
                                        </div>
                                   </Fragment>
                               })
                           }
                          
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default CampaignDetail;