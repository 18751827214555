import React, { useEffect, useState } from "react";
import "./Hero.scss";
import Slider from "react-slick";
import AOS from "aos";
import "aos/dist/aos.css";
import gradc from "../../assets/grad_bottom_c.png";
import { useMediaQuery } from "react-responsive";
const Hero = (props) => {
  // console.log(props, "PROPS");
  const [speed, setSpeed] = useState(16000);
  const settings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: speed,
    infinite: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "ease-in-out",
    afterChange: (current) => {
      if (current != 0) {
        setSpeed(5000);
      } else {
        setSpeed(16000);
      }
    },
  };

  useEffect(() => {
    AOS.init();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const Images = props && props.fields && props.fields["Background Images"];
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <div className="hero-container">
      <div
        className="hero-background"
        data-aos="fade-left"
        data-aos-duration="500"
      >
        {isMobile ? (
          <>
            <div className="res-radial-top"></div>
            <div className="res-radial-bottom"></div>
          </>
        ) : (
          <>
            <div className="bottom-radial"></div>
            <div className="top-radial"></div>
          </>
        )}
        <Slider {...settings}>
          {Images &&
            Images.map((item, index) => {
              const header =
                item.fields["Background Image"] &&
                item.fields["Background Image"].value.src;
              const showvideo =
                item.fields["Show Video"] && item.fields["Show Video"].value;
              const videolink =
                item.fields["Video Link"] &&
                item.fields["Video Link"].value &&
                item.fields["Video Link"].value.href;
              const position =
                item.fields.Position && item.fields.Position.value;
              const style = {
                objectPosition: `${position} 0%`,
              };
              return (
                <ul key={index} style={{ padding: "0px" }}>
                  {showvideo === true ? (
                    <>
                      <li>
                        <div
                          className="video-background"
                          style={{ backgroundImage: `url(${header})` }}
                        >
                          <div className="video-foreground ">
                            <iframe
                              src={videolink}
                              frameBorder="0"
                              allowFullScreen=""
                              title="vimeo video"
                            ></iframe>
                          </div>
                        </div>
                      </li>
                    </>
                  ) : (
                    <li>
                      <img
                        src={header}
                        fetchpriority="high"
                        alt="hero"
                        style={style}
                        className="hero-video"
                        key={index}
                      />
                    </li>
                  )}
                </ul>
              );
            })}
        </Slider>
      </div>
      <div className="hero-content">
        <div
          className="hero-tagline"
          data-aos="fade-up"
          data-aos-duration="500"
          dangerouslySetInnerHTML={{
            __html:
              props &&
              props.fields &&
              props.fields.Description &&
              props.fields.Description.value,
          }}
        >
          {/* <h1
            
          ></h1> */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
