
import React, {useRef} from 'react';
import Slider from "react-slick";
import {Modal} from 'react-bootstrap';
import closebluebg from "../../assets/closebluebg.PNG";
import leftarrow from "../../assets/leftbluebg-arrow.png";
import rightarrow from "../../assets/rightbluebg-arrow.png";

import './EventGalleryModal.scss';

const EventGalleryModal = (props) =>{
    const sliderRef = useRef();
     var settings = {
        dots: false,
        infinite: true,
        speed: 10,
        slidesToShow: props.indexvalue,
        slidesToScroll: props.indexvalue,
        initialSlide: props.indexvalue,
        autoplay:true,
        autoplaySpeed:20000,
        fade:true,
     }

    const gotoNext = () => {
        sliderRef.current.slickNext();
    }

    const gotoPrev = () => {
        sliderRef.current.slickPrev();
    }

   const goselectImage = () =>{
        sliderRef.current.slickGoTo(props.indexvalue);
   }
   if(props.show === true){
        setTimeout(goselectImage);
   }

    return <div  >
            <Modal {...props}   size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                 
                 <div className='container-fluid event-gallery-modal p-0' >
                       <Slider {...settings}  ref={sliderRef}  >
                           {
                                props.records.map((item,index) =>{
                                    const image = item.fields["Background Image"] && item.fields["Background Image"].value.src;
                                    return <div key={index} >
                                            <div className="profile-bg-image" key={index} style={{backgroundImage: `url(${image})`}} ></div>
                                        </div>
                                })  
                           }
                     </Slider> 

                        <div className='gallery-user-settings' >
                            <div className='left-close' >
                                <img src={closebluebg} onClick={props.onHide} alt='left-clo' />
                            </div>
                            <div className='mx-auto right-controls'>
                                <img src={leftarrow}  onClick={gotoPrev} className="left-arrow" alt='left' />
                                <img src={rightarrow} onClick={gotoNext} className="right-arrow" alt='right' />
                            </div>
                        </div>  
                 </div>
                 
            </Modal>
          </div>

}

export default EventGalleryModal;