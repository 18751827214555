import React, { Fragment } from 'react';
import dic from "i18next";
import OfferCard from '../CommonComponents/OfferCard/index';
import "./CardList.scss";
import { useMediaQuery } from 'react-responsive';
import MobileOfferCard from "../CommonComponents/MobileOfferCard/MobileOfferCard";
 
const CardList = (props) => {
  const colors = {
    "0":"#f7f4f2",
    "1":"#f7f1f0",
    "2":"#eff1f2",
    "3":"#eff1f2",
    "4":"#f7f4f2",
    "5":"#f7f1f0",
    "6":"#f7f1f0",
    "7":"#eff1f2",
    "8":"#f7f4f2"
  };
  const contentlist = props && props.fields && props.fields["Offers"];
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  return (
    <Fragment>
        { isMobile ? <MobileOfferCard contentlist={contentlist} ismember={props.ismember} mvtitle={props.mvtitle} /> : 
        <div className='cardlist-container'>
          <div className='title cg-offercard-heading'  data-aos="fade-up" data-aos-duration="1200"><h2>{dic.t("Jclub-Our Offerings")}</h2></div>
          <div className="pb-5" ></div>
          <div className='grid'>
            {
              contentlist.map((item, index) => {
                var duration = "";
                
               if( typeof window !== 'undefined' && window.innerWidth < 1020){
                   switch (index) {
                    case 0: case 2: case 4: case 6: case 8:
                      duration = 300;
                      break;
                    case 1: case 3: case 5: case 7:
                      duration = 500;
                      break;
                    default:   
                      duration = 300;
                  }
              }else{
                  switch (index) {
                    case 0: case 3: case 6:
                      duration = 300;
                      break;
                    case 1: case 4: case 7:
                      duration = 500;
                      break;
                    case 2: case 5: case 8:
                      duration = 700;
                      break;
                    default:   
                      duration = 300;
                  }
              }
                
                return <OfferCard index={index} time={index} colors={colors} duration={duration} contentlist={contentlist} key={index} />
              })
            }
           </div>
        </div> 
        }
    </Fragment>
  );
}

export default CardList;
