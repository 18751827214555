import React, { Fragment } from "react";
import moment from "moment";
import dic from "i18next";
const EventDetails = (props) => {
  const { Name, Description: desc, CTA } = props.fields.Event[0].fields;
  const title = Name.value;
  const Description = desc.value;
  const image = props.fields.Event[0].fields["Background Image"].value.src;
  const Dates = props.fields.Event[0].fields["Date"].value;
  const Locationhref = CTA.value.href;
  const LocationTitle = CTA.value.text;
  return (
    <Fragment>
      <div className="event-detail-main-container">
        <div className="row p-0 m-0">
          <div
            className="col-md-6 left-background-image"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
          <div className="col-md-6 col-12 right-background-event">
            <div className="event-title">
              <p>{title}</p>
            </div>
            <div className="show-mobile-detail">
              <p>DATE : {moment(Dates).format("DD MMMM YYYY")}</p>
            </div>
            <div className="show-mobile-location">
              <p>{LocationTitle}</p>
            </div>
            <div className="event-location-time">
              <p>
                Location:
                <span>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={Locationhref}
                    className="text-reset"
                  >
                    {` ${LocationTitle}`}
                  </a>
                </span>
              </p>
              <p>Date: {moment(Dates).format("DD MMMM YYYY")}</p>
            </div>
            <div className="event-description">
              <div dangerouslySetInnerHTML={{ __html: Description }}></div>
            </div>

            <div className="booknow-button d-none">
              <button className="btn">
                {dic.t("Jclub-Book Now", "Book Now")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EventDetails;
