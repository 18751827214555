import React from "react";
import Newscard from "../NewsCard";
import "./NewsArticle.scss";
import NewsMobileArticle from "./NewsMobileArticle";
import dic from "i18next";
const NewsArticle = (props) => {
  const { newslist, urltitle } = props;
  console.log(urltitle);

  const currentlist = newslist.filter((result, key) => {
    return result.fields["Name"].value === urltitle;
  });

  const balancelist = newslist.filter((result, key) => {
    return result.fields["Name"].value !== urltitle;
  });

  return (
    <>
      <NewsMobileArticle newslist={newslist} urltitle={urltitle} />
      <div className="article">
        <div className="row">
          <div className="col-md-12">
            <div className="row flex-column mx-0 mt-2">
              {currentlist.map((post, index) => {
                const { fields } = post;
                const title = fields["Name"].value;
                const Description = fields["Description"].value;
                const src = fields["Background Image"].value.src;
                return (
                  <div className="news-article" key={index}>
                    {/* <h2 className="article-title text-muted">{title}</h2> */}
                    {/* <img
                      className="article-image"
                      alt={`news-${index}`}
                      src={src}
                    /> */}
                    <div className="article-description">
                      <p dangerouslySetInnerHTML={{ __html: Description }}></p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="col-md-12 mt-5">
            <p className="text-muted related">
              {dic.t("Jclub-Latest Blogs", "Latest Blogs")}
            </p>
            <div className="row  mx-0 ">
              {balancelist.map((post, index) => {
                const { fields } = post;
                const title = fields["Name"].value;
                const short_description = fields["Short Description"].value;
                const image = fields["Background Image"].value.src;
                const readmore = fields["Read More"].value.href;
                return (
                  <div className="col-md-4 ">
                    <Newscard
                      // width={16}
                      key={index}
                      keyvalue={index}
                      title={title}
                      short_description={short_description}
                      image={image}
                      readmore={readmore}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsArticle;
