import React, { useEffect, useState, Fragment } from "react";
import "./Navbar.scss";
import BurgerMenu from "./BurgerMenu";
import whatsappicon from "../../assets/whatsapp-logo.png";

const Navbar = (props) => {
  const [scrollNav, setScrollNav] = useState(false);
  const changeNav = () => {
    if (typeof window !== "undefined" && window.scrollY >= 60) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };
  const current_location_name =
    typeof window !== "undefined" && window.location.pathname;
  useEffect(() => {
    if (current_location_name === "/en/sitemap/") {
      setScrollNav(true);
    } else {
      typeof window !== "undefined" &&
        window.addEventListener("scroll", changeNav);
    }
  }, []);
  const isChildPage = (route1, route2, path) => {
    return route1 && route1.includes(path) && route2 && route2.includes(path);
  };

  // Only render the script if the current path is the home page
  useEffect(() => {
    const homePaths = ["/", "/en", "/en/", "/ar", "/ar/"];
    if (homePaths.includes(window.location.pathname)) {
      const script = document.createElement("script");
      script.type = "application/ld+json";
      script.textContent = JSON.stringify({
        "@context": "http://schema.org",
        "@type": "Organization",
        name: "J Club",
        description:
          "Enjoy the best of fitness, lifestyle and leisure in one holistic environment with Dubai's leading luxury health and fitness center, J Club.",
        image:
          "https://dxp-hospitality-uat-rg-sit-85270c-cd.azurewebsites.net//-/mediadh/dh/hospitality/jclub/logo/jclub_header_logo.png?h=296&w=296&modified=20220308135113",
        logo:
          "https://dxp-hospitality-uat-rg-sit-85270c-cd.azurewebsites.net//-/mediadh/dh/hospitality/jclub/logo/jclub_header_logo.png?h=296&w=296&modified=20220308135113",
        url: "Organization",
        telephone: "9711234567890",
        sameAs: [
          "https://www.facebook.com/thejclubdxb",
          "https://www.instagram.com/jclubdxb/?hl=en",
          "https://www.linkedin.com/company/thejclub/"
        ]
      });

      document.head.appendChild(script);
    }
  }, []);

  return (
    <Fragment>
      {current_location_name !== "/en/sitemap/" && (
        <div
          dangerouslySetInnerHTML={{
            __html: props && props.topnavigation && props.topnavigation.value,
          }}
        ></div>
      )}
      <nav className={`${scrollNav ? "navbar-black" : "navbar-transparent"}`}>
        <div className="navbar-container">
          <div className="logo">
            <a href={"/en"}>
              <img
                src={props.logo}
                alt="logo"
                height={255}
                width={296}
                className="logo-image"
              />
            </a>
          </div>
          <ul className="navlist">
            {props.navigation.map((item, index) => {
              const CTALink =
                item.fields["Direct CTA Link"] &&
                item.fields["Direct CTA Link"].value.href;
              const second =
                item.fields["Secondary Navigations"] &&
                item.fields["Secondary Navigations"];
              return item.fields["Show In Header"] &&
                item.fields["Show In Header"].value ? (
                <li className="navitem menulist" key={index}>
                  <a
                    className={`${
                      CTALink === current_location_name ||
                      isChildPage(
                        CTALink,
                        current_location_name,
                        "/memberships"
                      ) ||
                      isChildPage(CTALink, current_location_name, "/locations")
                        ? "selected"
                        : ""
                    }`}
                    href={`${CTALink === undefined ? "#" : CTALink}`}
                  >
                    {" "}
                    {item.fields["Page Title"] &&
                      item.fields["Page Title"].value}
                  </a>
                  {(CTALink === "/en/locations" ||
                    CTALink === "/en/memberships") && second &&(
                    <ul
                      className={`secondlist ${scrollNav &&
                        "secondlist-color"}`}
                    >
                      {second &&
                        second.map((item) => {
                          const CTALink =
                            item.fields["Direct CTA Link"] &&
                            item.fields["Direct CTA Link"].value.href;
                          const Title =
                            item.fields["Page Title"] &&
                            item.fields["Page Title"].value;
                          return (
                            <li key={index++}>
                              <a href={CTALink}>{Title}</a>
                            </li>
                          );
                        })}
                    </ul>
                  )}
                </li>
              ) : (
                ""
              );
            })}
            {props.links.map((item, index) => {
              const href =
                item.fields["Direct CTA Link"] &&
                item.fields["Direct CTA Link"].value.href;
              return item.fields["Link Name"].value === "MEMBER LOGIN" ? (
                href ? (
                  <li className="navitem" key={index}>
                    <a
                      className={`box-border  ${
                        current_location_name === href ? "selected" : ""
                      }`}
                      href={`${href === undefined ? "#" : href}`}
                    >
                      {item.fields["Link Name"].value}
                    </a>
                  </li>
                ) : (
                  ""
                )
              ) : item.fields["Link Name"].value === "JOIN NOW" ? (
                href ? (
                  <li className="navitem menulist" key={index}>
                    <a
                      href={`${href === undefined ? "#" : href}`}
                      className={` join-now ${
                        current_location_name === href ? "selected" : ""
                      }`}
                    >
                      {item.fields["Link Name"].value}
                    </a>
                  </li>
                ) : (
                  ""
                )
              ) : (
                <li className="navitem menulist" key={index}>
                  <a
                    href={`${href === undefined ? "#" : href}`}
                    className={`${
                      current_location_name === href ? "selected" : ""
                    }`}
                  >
                    {item.fields["Link Name"].value}
                  </a>
                </li>
              );
            })}
            <li className="px-2 burger-menu-li ">
              <BurgerMenu
                social_links={props.social_links}
                navigation={props.navigation}
                logo={props.logo}
                links={props.links}
              />
            </li>
          </ul>
        </div>
      </nav>
      <div className="whats-app-icon d-none">
        <a
          href="https://api.whatsapp.com/send/?phone=971555008543&text&type=phone_number&app_absent=0"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={whatsappicon}
            style={{ display: "inline", width: "3rem" }}
          />
        </a>
      </div>
    </Fragment>
  );
};

export default Navbar;
