import React, {useState} from 'react';
import './style.scss';
import EventGalleryModal from './EventGalleryModal';
import EventVideoModal from './EventVideoModal';
import events from "../../assets/events.png";
import cycling from "../../assets/cycling.png";
import allcycling from '../../assets/allcycling.PNG';
import ladiescycling from '../../assets/ladiescycling.png';
import { FaPlayCircle } from 'react-icons/fa';

const EventGallery = (props) =>{

    const [modalShow, setModalShow] = useState(false);
    const [indexValue, setindexValue] = useState(0);
    const [tabStatus, settabStatus] = useState(true);
    const Galleryimage = props && props.posts[0] && props.posts[0].fields.Images;
    const GalleryVideo = props && props.posts[0] && props.posts[0].fields.Video;
    
     
    const Clickimage = (index) =>{
        setModalShow(true);
        setindexValue(index);
    }
   
    const data = [
        {
            src:cycling,
        },
        {
            src:allcycling,
        },
        {
            src:ladiescycling,
        },
        {
            src:cycling,
        },
        {
            src:allcycling,
        },
        {
            src:ladiescycling,
        },
        {
            src:cycling,
        },
        {
            src:allcycling,
        },
        {
            src:ladiescycling,
        },
        {
            src:cycling,
        },
        {
            src:allcycling,
        },
        {
            src:ladiescycling,
        }
        
     ]

      
    const datavideo = [
        {
            src:"https://cdn.jumeirah.com/-/mediadh/dh/hospitality/jumeirah/hotels/dubai/burj-al-arab-jumeirah/usp/burj-al-arab-profile-exterior_6-4_landscape.jpg",
            videosrc: "https://assets.mixkit.co/videos/preview/mixkit-ocean-waves-bursting-on-the-shore-of-the-coast-4078-large.mp4",
        },
        {
            src:events,
            videosrc: "https://assets.mixkit.co/videos/preview/mixkit-tour-along-the-coast-4062-large.mp4",
        },
        {
            src:"https://cdn.jumeirah.com/-/mediadh/dh/hospitality/jumeirah/hotels/dubai/burj-al-arab-jumeirah/usp/burj-al-arab-profile-exterior_6-4_landscape.jpg",
            videosrc: "https://assets.mixkit.co/videos/preview/mixkit-ocean-waves-bursting-on-the-shore-of-the-coast-4078-large.mp4",

        },
        {
            src:events,
            videosrc: "https://assets.mixkit.co/videos/preview/mixkit-tour-along-the-coast-4062-large.mp4",
        }
       
     ]

     const handleTab = (status) =>{
        settabStatus(status);
     }      

    return  <>
        {
            (Galleryimage && Galleryimage.length > 0 ) ||  (GalleryVideo && GalleryVideo.length > 0) ? 
            <div className='event-gallery-main-container'>
            <div className='events-gallery-title' >
                <p className='title'>EVENT GALLERY</p>
                <p className='description' >
                    { tabStatus ? 
                    <span className='activeclass'  onClick={ ()=> handleTab(true)} >Photos</span>
                     :  <span    onClick={ ()=> handleTab(true)} >Photos</span> }   
                     &nbsp;|&nbsp; 
                     { tabStatus ? 
                     <span   onClick={ () => handleTab(false)} >Videos</span>
                     : <span className='activeclass'  onClick={ () => handleTab(false)} >Videos</span>
                    }
                     </p>
            </div>
             {
                  tabStatus ? 
                   
                   <div className='container-fluid event-gallry-image'>
                        <div className='row'>
                            {
                                Galleryimage.length > 0 ?

                                   Galleryimage.map((item,index) =>{
                                        
                                            const image = item.fields["Background Image"] && item.fields["Background Image"].value.src;

                                            return <div className='col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 gallery-background-sub' key={index} >
                                                <span variant="primary" onClick={() => Clickimage(index) }  >
                                                    <div className='gallery-background-image' style={{backgroundImage: `url(${image})`}} ></div>
                                                    </span> 
                                            </div>    
                                    })
                                
                                :  <p className='text-center mx-auto times-new-roman' > Currently we don't have any photos </p>

                            }
                        </div>   
                    <EventGalleryModal show={modalShow} indexvalue={indexValue}  records={Galleryimage} onHide={() => setModalShow(false)} />
                   </div>
                  : 
                  <div className='container-fluid event-gallry-image'>
                        <div className='row'>
                            {
                               GalleryVideo && GalleryVideo.length > 0 ? 
                                   GalleryVideo.map((item,index) =>{
                                            const image = item.fields["Background Image"] && item.fields["Background Image"].value.src;

                                            return <div className='col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 gallery-background-sub' key={index}  >
                                                <span variant="primary" onClick={() => Clickimage(index) } href={() => false}>
                                                    <div className='gallery-background-image' style={{backgroundImage: `url(${item.src})`}} >
                                                     <div className='play-button'><FaPlayCircle /></div> 
                                                    </div>
                                                    </span> 
                                            </div>    
                                    })

                                    : <p className='text-center mx-auto times-new-roman' > Currently we don't have any videos </p>
                            }
                        </div>   
                        {
                               GalleryVideo && GalleryVideo.length > 0 ? 
                                     <EventVideoModal show={modalShow} indexvalue={indexValue}  records={GalleryVideo} onHide={() => setModalShow(false)} />
                               : ""
                        }
                  </div>

             }
              
        </div>

            :  ""
        } 
            

            </>

}

export default EventGallery;