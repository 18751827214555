import React, { Component, useState } from "react";
// import { SITECORE_CONSTANTS } from "../../siteCoreConstants";
import RightArrow from '../../assets/white-arrow-right.png';
import './styles.scss';

const DetailFaq = (props) => {
  const [isAnswerVisible, setisAnswerVisible] = useState({});
  const showAnswers = (index) => {
    setisAnswerVisible({ isAnswerVisible, [index]: isAnswerVisible[index] ? !isAnswerVisible[index] : true } )
  }
  const { fields } = props && props.fields && props.fields["FAQ Detail List"] && props.fields["FAQ Detail List"][0];
    let faqDataQuestionCategories =  fields["QuestionAns List"];
    let Title = fields.Title.value;
    let Tagline = fields.Tagline.value;
    return (
      <div className="full-width-component hotel-faq-wrapper">
        <div className="hotel-faq-title">{Title}</div>
        <div className="hotel-faq-subtitle">{Tagline}</div>
        {faqDataQuestionCategories && faqDataQuestionCategories.map((list, index) => {
          let answers=list.fields && list.fields.Answers && list.fields.Answers.value
          return (
            <div id={index} onClick={() => showAnswers(index)} >

              <div className="question-description">{list.fields.Question.value}
                <span className="p-2">
                  <img src={RightArrow} alt="Arrow" className={`arrow-icon ${isAnswerVisible[index] ? "rotate" : ""}`} />
                </span>
              </div>
              {isAnswerVisible[index] && <div className="answer-description" dangerouslySetInnerHTML={{__html:answers}}></div>}
              <div className={`border-bottom-label ${isAnswerVisible[index] ? "border-bottom-label-wrapper" : "no-border"}`}></div>
            </div>
          )
        })}
      </div>
    )
};

export default DetailFaq;