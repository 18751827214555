import React , {useState,useRef} from 'react';
import "./ReadMoreButton.scss";
import { FaTimes } from "react-icons/fa";
import Slider from "react-slick";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import { IconContext } from "react-icons";

const ReadMoreButton = (props) => {
    const [currentindex, setCurrentindex] = useState(props.index);
    const nextindex = currentindex + 1;
    const previndex = currentindex - 1;
    const sliderRef = useRef();
    var settings = {
        dots: true,
        autoplay:true,
        autoplaySpeed: 6000,
        infinite: true,
        fade:true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
    }
    if(props.openbox){
        sliderRef.current.slickGoTo(0);
    }
    const nextmodal = () => {
        setCurrentindex(nextindex);
        sliderRef.current.slickGoTo(0);
      };
    const prevmodal = () => {
        setCurrentindex(previndex);
        sliderRef.current.slickGoTo(0);
    }
    const closemodal = () => {
        props.update();
        setCurrentindex(props.index);
    }
    
    let findoutstatus = props.contentlist[currentindex].fields["Show Find Out CTA"].value;
    const srcimagecontent = props.contentlist[currentindex].fields["Background Images"] && props.contentlist[currentindex].fields["Background Images"][0];
    const srcimage =  srcimagecontent &&  srcimagecontent.fields["Background Image"] && srcimagecontent.fields["Background Image"].value && srcimagecontent.fields["Background Image"].value.src;

    return (
        <div className='top-level  position-absolute'>
            <div className={`${props.openbox ? 'lightbox-effect' : 'lightbox'}`}>
                <div className='box-wrapper modal-dialog-centered'>
                    <div className={`${props.openbox ? 'box-effect' : 'box'}`}>
                        <div className='modal-card'>
                            <div className='modal-inner-card'>
                                <div className='row m-0'>
                                    <div className='col-md-6 px-0'>
                                        <div className='modal-image-wrapper'>
                                            <Slider {...settings} ref={sliderRef}>
                                                <img src={srcimage} alt="img" className='modal-image' />
                                            </Slider>
                                        </div>
                                    </div>    
                                    <div className='col-md-6 px-0'>
                                        
                                        <div className='modal-content-new'>
                                             <div className='close-btn' onClick={closemodal}><FaTimes /></div>

                                            <div className='modal-content-title'>
                                                {props.contentlist[currentindex].fields.Title.value}
                                            </div>
                                            <div className='modal-content-description'>
                                                <div className={findoutstatus ? "modal-info-description-with-findmore" : 'modal-info-description'} >
                                                    <div dangerouslySetInnerHTML={{
                                                        __html: props.contentlist && props.contentlist[currentindex].fields && props.contentlist[currentindex].fields.Description.value
                                                    }}></div>
                                                </div>
                                                {
                                                    findoutstatus ?  
                                                        <div className='findout-more pb-2 pt-1'>
                                                                <a href={props.contentlist[currentindex].fields["Find Out CTA"].value.href} target="_blank" rel="noopener noreferrer" className="text-reset text-decoration-none" ><button className="btn">Find Out More</button></a> 
                                                        </div> 
                                                    : ""
                                                }
                                               
                                                <div className={findoutstatus ? "next-item-findmore" : 'next-item'} >
                                                    <p className='left-arrow'  onClick={prevmodal} >
                                                        {previndex < 0 ? "" : <IconContext.Provider value={{ className: "arrow-icon" }} ><IoChevronBackOutline /></IconContext.Provider>}
                                                        {props.contentlist[previndex] && props.contentlist[previndex].fields.Title.value}
                                                    </p>
                                                    <p className='right-arrow' onClick={nextmodal}>
                                                        {props.contentlist[nextindex] && props.contentlist[nextindex].fields.Title.value}
                                                        {nextindex === props.contentlist.length ? "" : <IconContext.Provider value={{ className: "arrow-icon" }} ><IoChevronForwardOutline /></IconContext.Provider> }
                                                    </p>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>            
                                </div>            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReadMoreButton;
