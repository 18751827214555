import React from "react";
import "./MembershipDetails.scss";

const MembershipDetails = (props) => {
  const { value } = props && props.fields && props.fields["Membership Info"];
  return (
    <>
      <div
        className="membership-deatils"
        dangerouslySetInnerHTML={{ __html: value }}
      ></div>
    </>
  );
};

export default MembershipDetails;
