import React from "react";
import "./style.scss";
import NewsArticle from "../News/NewsArticle";

const NewsDetails = (props) => {
  const { Name } = props.fields["Article Detail"][0].fields;
  const featuredNews = props.fields["Article Detail"];
  const NewsTitle = props.fields.Title.value;
  return (
    <div>
      <div className="news-info-section">
        <div className="news-info-title mx-auto py-0">
          <h2>{NewsTitle}</h2>
        </div>
      </div>
      <NewsArticle newslist={featuredNews} urltitle={Name.value} />
    </div>
  );
};

export default NewsDetails;
