import React, { useEffect, useState } from 'react';
import { useForm , Controller} from 'react-hook-form';
import dic from "i18next";
import Select from 'react-select';
import { useMediaQuery } from 'react-responsive';
import TextareaAutosize from 'react-textarea-autosize';
import axios from 'axios';
import "./BookATour.scss";
import { HiRefresh } from 'react-icons/hi';
import { EmailServiceURL , ReCaptchaSiteVerify } from '../../constants';

const BookTour = (props) => {
    const [captchaLoadError ,setRecaptchaError] = useState(false);
    const [captchaToken, setCaptcha] = useState(false);
    const [isSubmitted, setisSubmitted] = useState(false);
    const [isnotSubmitted, setisNotSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const RECAPTCHA_SITE_KEY = '6Le-_ZEfAAAAAFrPTntQLZAf3RM-8rVfA8UQdFDw';
    const title = props && props.fields && props.fields.Title.value;
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const letters = /^[a-zA-Z_ ]*$/;
    const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const mobilePattern = /[1-9][0-9]{9,14}/;
    const [getContact, setContact] = useState('');
    const handleCheckNumber = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        setContact(value);
    };
    const { register, handleSubmit, formState: { errors } , control , reset } = useForm({defaultValues: { NatureofEnquiry: "" , Clublocation: ""}});
    const onSubmits =async(data) => { 
        setIsLoading(true);
        var today = new Date();
        var date = today.getDate() +'-'+(today.getMonth()+1)+'-'+today.getFullYear();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        data.formfilltime = time;
        data.formfilldate = date;
        if(captchaToken){
            const siteconfig = {
                captchaToken:captchaToken
            };
            const res = await axios.post(ReCaptchaSiteVerify,siteconfig);
            if(res.data.status === 200){
                axios.post(EmailServiceURL,data)
                .then((res) => {
                    setIsLoading(false);
                    reset(); 
                    window.scrollTo({
                        top:550,
                        bottom:0,
                        behavior:'smooth'
                    });
                    setisSubmitted(true);
                }).catch((error) => {
                setisNotSubmitted(true);
                    setIsLoading(false);
                    window.scrollTo({
                        top:550,
                        bottom:0,
                        behavior:'smooth'
                    });
                });
            }else{
                alert("Invalid Captcha ... Please try again later");
                window.location.href = "/en/book-a-tour";
            } 
        }
    };

    //options for react select dropdown list
    const Natureoptions = props && props.fields["Nature Of Enquires"].map( item => (
        { label : item.fields.Title.value , value : item.fields.Title.value }
      ));
    const options = props && props.fields["Locations"].map( item => (
        { label : item.fields.Title.value , value : item.fields.Title.value }
      ));

    //below styles are applied to react select when required is true
    const notValidcolourStyles = {
        control: (styles,state) => ({ 
            ...styles, 
            backgroundColor: 'transperant' ,  
            borderRadius: 10 , 
            minHeight:'30px',
            height:'30px',
            borderColor:'#dc3545',
            '&:hover': { borderColor:'#dc3545'},
            boxShadow:'none'
        }),
        indicatorSeparator: (styles) => ({display:'none'}),
        dropdownIndicator: base => ({
            ...base,
            position: "relative",
            top:"-4px",
            color: (errors.Clublocation && errors.Clublocation.type === "required") ||  (errors.NatureofEnquiry && errors.NatureofEnquiry.type === "required") ? '#dc3545' : '#abb8bd',
        }),
        valueContainer: (defaultStyles) => {
            return {
                ...defaultStyles,
                position: 'relative',
                top:'-4px',
            }
        },
        input: (base) => ({
            ...base,
            color: 'white',
        }),
        singleValue: (base) => ({
            ...base,
            color: 'white',
        }),
        menuList: (defaultStyles) => {
            return {
                ...defaultStyles,
                "::-webkit-scrollbar": {
                width: "5px"
                },
                "::-webkit-scrollbar-track": {
                background: '#f1f1f1',
                boxShadow: 'inset 0 0 5px grey',
                borderRadius: '5px',
                },
                "::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: '5px',
                },
            }
        },
    };

    //below styles are applied to react select when required is false
    const colourStyles = {
        control: (styles,state) => ({ 
            ...styles, 
            backgroundColor: 'transperant' ,
            color:'#ffffff',  
            borderRadius: 10 , 
            minHeight:'30px',
            height:'30px',
            borderColor:'#abb8bd',
            '&:hover': { borderColor:'#abb8bd' },
            boxShadow:'none'
        }),
        indicatorSeparator: (styles) => ({display:'none'}),
        dropdownIndicator: base => ({
            ...base,
            position: "relative",
            top:"-4px",
            color: (errors.Clublocation && errors.Clublocation.type === "required") ||  (errors.NatureofEnquiry && errors.NatureofEnquiry.type === "required") ? '#dc3545' : '#abb8bd',
          }),
        valueContainer: (defaultStyles) => {
            return {
                ...defaultStyles,
                position: 'relative',
                top:'-4px',
            }
        },
        input: (base) => ({
            ...base,
            color: 'white',
        }),
        singleValue: (base) => ({
            ...base,
            color:'white', 
        }),
        menuList: (defaultStyles) => {
            return {
                ...defaultStyles,
                "::-webkit-scrollbar": {
                  width: "5px"
                },
                "::-webkit-scrollbar-track": {
                  background: '#f1f1f1',
                  boxShadow: 'inset 0 0 5px grey',
                  borderRadius: '5px',
                },
                "::-webkit-scrollbar-thumb": {
                  background: "#888",
                  borderRadius: '5px',
                },
            }
        },
      };

      const loadScriptByURL = (id, url, callback) => {
        
        const isScriptExist = document.getElementById(id);
        if (!isScriptExist) {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            script.id = id;
            script.onload = function () {
                if (callback) callback();
            };
            script.onerror = function() {
                setRecaptchaError(true);
            };
            document.body.appendChild(script);
        }

        if (isScriptExist && callback) callback();
    }



    useEffect(() => {
    // load the script by passing the URL
        const URL = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
        const onLoadSuccessCaptcha = () => {

             if (!window || !window.grecaptcha) {
                console.log("Error on loading Google ReCaptcha");
                setRecaptchaError(true);
                return;
            } else {
         
                const grecaptchaReady = async () => {
                    let token = "";
                    setRecaptchaError(false);
                    try{
                        token = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'submit' });
                    }catch(e){
                        window.location.href = "/en/book-a-tour";
                    }
                    setCaptcha(token);
                }

                window.grecaptcha.ready(() => {
                    grecaptchaReady();
                });

            }
        }

        loadScriptByURL("recaptcha-key-v3-book", URL, onLoadSuccessCaptcha);
    }, []);



    return (
        <>
        <div className='form'>
            <h2 className='form-title'>{title}</h2>
            <div className='popup'>
                <p className='row justify-content-center'>{isSubmitted && dic.t("Jclub-Thank you for your Message") }</p>
                <p className='row justify-content-center'>{isnotSubmitted && "Something Went Wrong Please try again later..." }</p>
            </div>
            <div className='form-wrapper'>
                <form onSubmit={handleSubmit(onSubmits)}>
                    <div className='row'>
                        <div className='col-md-6 p-2'>
                            <label>{dic.t("Jclub-First Name")}*</label>
                            <input maxLength={20}  type="text"  {...register("firstName", { 
                                required: `${dic.t("Jclub-First Name is required")}`,  
                                minLength: {
                                    value:3,
                                    message: `${dic.t("Jclub-Enter at least 3 Characters")}`
                                },
                                pattern:{
                                    value:letters,
                                    message: `${dic.t("Jclub-Please enter only letters")}`
                                }
                            })} autoComplete="off" className={`${ errors.firstName && errors.firstName.message ?'border-danger':''}`} />
                            {errors.firstName && errors.firstName.message && <span className="error text-danger">{errors.firstName.message}</span>}
                        </div>
                        <div className='col-md-6 p-2'>
                            <label>{dic.t("Jclub-Last Name")}*</label>
                            <input type="text" maxLength={20}   {...register("lastName", { 
                                required: `${dic.t("Jclub-Last Name is required")}`, 
                                minLength: {
                                    value:3,
                                    message: `${dic.t("Jclub-Enter at least 3 Characters")}`
                                },
                                pattern:{
                                    value:letters,
                                    message: `${dic.t("Jclub-Please enter only letters")}`
                                } 
                            })} autoComplete="off" className={`${ errors.lastName && errors.lastName.message ?'border-danger':''}`} />
                            {errors.lastName && errors.lastName.message && <span className="error text-danger">{errors.lastName.message}</span>}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 p-2'>
                            <label>{dic.t("Jclub-Mobile Number")}*</label>
                            <input type="text" maxLength={15} {...register("MobileNumber", { 
                                required: `${dic.t("Jclub-Mobile Number is required")}`, 
                                pattern:{
                                    value: mobilePattern,
                                    message: `${dic.t("Jclub-Please Enter a Valid Mobile Number")}`
                                }
                            })} value={getContact} onChange={handleCheckNumber} autoComplete="off" className={`${ errors.MobileNumber && errors.MobileNumber.message ? 'border-danger':''}`} />
                            {errors.MobileNumber && errors.MobileNumber.message && <span className="error text-danger">{errors.MobileNumber.message}</span>}
                        </div>
                        <div className='col-md-6 p-2'>
                            <label>{dic.t("Jclub-Nature Of Enquiry")}*</label><br/>
                            <Controller
                                name="NatureofEnquiry"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <Select  {...field} options={Natureoptions} placeholder={''} styles={ errors.NatureofEnquiry && errors.NatureofEnquiry.type === "required" ? notValidcolourStyles : colourStyles  }  />}
                            />
                            {errors.NatureofEnquiry && errors.NatureofEnquiry.type === "required" ? <span className="error text-danger">{dic.t("Jclub-Nature of Enquiry is required")}</span>:''}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 p-2'>
                            <label>{dic.t("Jclub-Email Address")}*</label>
                            <input type="text"  {...register("email", {
                                required: 'Email is Required',
                                pattern: {
                                    value: emailPattern,
                                    message: `${dic.t("Jclub-Please enter a valid email")}`,
                                },
                            })} autoComplete="off" className={`${ errors.email && errors.email.message ? 'border-danger':''}`} />
                            {errors.email && errors.email.message && <span className="error text-danger">{errors.email.message}</span>}
                        </div>
                        <div className='col-md-6 p-2'>
                            <label>{dic.t("Jclub-Club Location")}*</label>
                            <Controller
                                name="Clublocation"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => <Select {...field} options={options} placeholder={''} styles={errors.Clublocation && errors.Clublocation.type === "required" ? notValidcolourStyles:colourStyles} />}
                            />
                            {errors.Clublocation && errors.Clublocation.type === "required" ? <span className="error text-danger">{dic.t("Jclub-Club Location is required")}</span>:''}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 p-2'>
                            <label className={`m-0`}>{dic.t("Jclub-Comments")}</label>
                            <TextareaAutosize id="w3review" name="Comments" maxLength={500} minRows={4} className={ errors.Comments ? 'border-danger':''} {...register("Comments",{
                                minLength: {
                                    value:3,
                                    message: `${dic.t("Jclub-Enter at least 3 Characters")}`
                                }
                            })} />
                            {errors.Comments && errors.Comments.message && <span className="error text-danger">{errors.Comments.message}</span>} 
                        </div>
                        <div className='col-md-6 p-2 mt-auto'>
                            <div className='row pl-4'>
                                <div className='col-md-12 p-2'>
                                    <div className='row'>
                                        <p className='required'>* {dic.t("Jclub-Required Field")}</p>
                                    </div>
                                    <div className='row py-1'>
                                        <input type='checkbox'  {...register("privacy", { required: true})} />
                                        <label className={`m-0`}>Accept <span><a target="_blank" rel="noopener noreferrer" style={{ color:'white' , textDecoration: 'underline'}} href="https://www.jumeirah.com/en/jumeirah-group/terms-and-conditions">T&C</a></span> & <span><a target="_blank" rel="noopener noreferrer" style={{ color:'white' , textDecoration: 'underline'}} href="https://www.jumeirah.com/en/jumeirah-group/terms-and-conditions/jumeirah-privacy-notice">Privacy Policy</a></span> <span className={`${errors.privacy ? 'text-danger':''}`}>*</span></label>
                                        {/* {errors.privacy && errors.privacy.message && <span className="error text-danger">{errors.privacy.message}</span>} */}
                                    </div>
                                    <div className='row'>
                                        <span><label className={`m-0 ${errors.terms ? 'text-danger':''}`}>   <input type='checkbox'  style={{position:'relative',top:'3px'}}  className='pt-1'  {...register("marketing")} />{dic.t("Jclub-Marketing Message").replace(/{/i, '').replace(/}/i,'').replace(0,'')} <a target="_blank" rel="noopener noreferrer" style={{ color:'white' , textDecoration: 'underline'}} href="https://www.jumeirah.com/en/jumeirah-group/terms-and-conditions/jumeirah-privacy-notice">Privacy Policy</a> </label> </span>
                                        {/* {errors.terms && errors.terms.message && <span className="error text-danger">{errors.terms.message}</span>} */}
                                    </div>
                                </div>
                                <div className={`${isMobile ? 'col-md-4 p-2 mt-auto text-center':'col-md-12 p-2 text-right mt-auto'}`}>
                                    {/* <input type="submit" /> */}
                                    <button type="submit" className="submit-btn" disabled={isLoading}>
                                        {isLoading && ( <HiRefresh icon="spinner" className="spinner" /> )}
                                        {isLoading && "Submitting..."}
                                        {!isLoading && dic.t("Jclub-Submit")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </>
    );
}

export default BookTour;