import React, { useState,useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import dic from "i18next";
import "./NewsletterMobile.scss"
import axios from 'axios';
import { HiRefresh } from 'react-icons/hi';
import { NewsletterEmailServiceURL,ReCaptchaSiteVerify } from '../../../constants';
import Select from 'react-select';
import backgroundnews from '../../../assets/newsback.jpg';

const NewsletterMobile = (props) => {

    const [isSubmitted, setisSubmitted] = useState(false);
    const [isnotSubmitted, setisNotSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [captchaLoadError ,setRecaptchaError] = useState(false);
    const [captchaToken, setCaptcha] = useState(false);
    const RECAPTCHA_SITE_KEY = '6Le-_ZEfAAAAAFrPTntQLZAf3RM-8rVfA8UQdFDw';
    const letters =  /^[a-zA-Z_ ]*$/;
    const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const mobilePattern = /[0-9][0-9]{8,14}/;
    const [getContact, setContact] = useState('');
    const handleCheckNumber = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        setContact(value);
    };
    const { register, handleSubmit, formState: { errors }, control, reset } = useForm();
    const [options, setoptions] = useState([
        { label: 'J Club, Jumeirah Beach Hotel', value: 'J Club, Jumeirah Beach Hotel'},
        { label: 'J Club, Jumeirah Mina A’Salam', value: 'J Club, Jumeirah Mina A’Salam'}]);
    //below styles are applied to react select when required is false
    const colourStyles = {
        control: (styles,state) => ({ 
            ...styles, 
            backgroundColor: 'transperant' ,
            color:'#abb8bd',  
            borderRadius: 10 , 
            minHeight:'30px',
            height:'30px',
            fontSize: '12px',
            borderColor:'#abb8bd',
            '&:hover': { borderColor:'#abb8bd' },
            boxShadow:'none'
        }),
        indicatorSeparator: (styles) => ({display:'none'}),
        dropdownIndicator: base => ({
            ...base,
            position: "relative",
            top:"-4px",
            color: (errors.Clublocation && errors.Clublocation.type === "required") ||  (errors.NatureofEnquiry && errors.NatureofEnquiry.type === "required") ? '#dc3545' : '#abb8bd',
          }),
        valueContainer: (defaultStyles) => {
            return {
                ...defaultStyles,
                position: 'relative',
                top:'-4px',
            }
        },
        input: (base) => ({
            ...base,
            color: '#abb8bd',
        }),
        singleValue: (base) => ({
            ...base,
            color:'#abb8bd', 
        }),
        placeholder: (defaultStyles) => {
            return{
                ...defaultStyles,
                color: '#abb8bd'
            }
        },
        menuList: (defaultStyles) => {
            return {
                ...defaultStyles,
                color:'black',
                fontSize:'12px',
                "::-webkit-scrollbar": {
                  width: "5px"
                },
                "::-webkit-scrollbar-track": {
                  background: '#f1f1f1',
                  boxShadow: 'inset 0 0 5px grey',
                  borderRadius: '5px',
                },
                "::-webkit-scrollbar-thumb": {
                  background: "#888",
                  borderRadius: '5px',
                },
            }
        },
      };
    const onSubmit =async(data) =>  { 
        var today = new Date();
        var date = today.getDate() +'-'+(today.getMonth()+1)+'-'+today.getFullYear();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        data.formfilltime = time;
        data.formfilldate = date;
        setIsLoading(true);        
        if(captchaToken){
            const siteconfig = {
                captchaToken:captchaToken
            };
            const res = await axios.post(ReCaptchaSiteVerify,siteconfig);
            if(res.data.status === 200){

                axios.post(NewsletterEmailServiceURL,data)
                    .then((res) => {
                        setIsLoading(false);
                        reset(); 
                        setisSubmitted(true);
                    }).catch((error) => {
                        console.log(error);
                        setIsLoading(false);
                        reset(); 
                        setisNotSubmitted(true);
                    });

        }else{
            alert("Invalid Captcha ... Please try again later");
            window.location.href = "/en";
        } 
    }


    };
    const formfieldchanged = () => {
        setisSubmitted(false);
    }
    
    const loadScriptByURL = (id, url, callback) => {
        
        const isScriptExist = document.getElementById(id);
        if (!isScriptExist) {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            script.id = id;
            script.onload = function () {
                if (callback) callback();
            };
            script.onerror = function() {
                setRecaptchaError(true);
            };
            document.body.appendChild(script);
        }

        if (isScriptExist && callback) callback();
    }


    useEffect(() => {
        // load the script by passing the URL
            const URL = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
            const onLoadSuccessCaptcha = () => {
    
                 if (!window || !window.grecaptcha) {
                    console.log("Error on loading Google ReCaptcha");
                    setRecaptchaError(true);
                    return;
                } else {
             
                    const grecaptchaReady = async () => {
                        let token = "";
                        setRecaptchaError(false);
                        try{
                            token = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'submit' });
                        }catch(e){
                            window.location.href = "/en/book-a-tour";
                        }
                        setCaptcha(token);
                    }
    
                    window.grecaptcha.ready(() => {
                        grecaptchaReady();
                    });
    
                }
            }
    
            loadScriptByURL("recaptcha-key-v3-mobile", URL, onLoadSuccessCaptcha);
      }, []);
    


    return (
        <div className='newsletter-mobile newsletter-mobileview' style={{ backgroundImage: `url(${backgroundnews})` }} >
            <div className='newsletter-form'>
                <div className='row py-4 m-0'>
                    <div className='container'>
                        <div className='row'  >
                            <div className='col-2 col-sm-2 col-md-2 '></div>
                            <div className='col-lg-12 col-md-8 pt-2 col-sm-8 col-10 newslettermobileview-title'>
                                {props.title}
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} className="myform">
                            <div className='row'>
                                <div className='col-2 col-md-2 col-sm-2 '></div>
                                <div className='col-lg-6 col-md-4 col-5 col-sm-4 firstname-padding'>
                                    <input type="text" className="form-control" maxLength={20} onKeyUp={formfieldchanged} placeholder={`${dic.t("Jclub-First Name").toUpperCase()}`} {...register("firstName", { 
                                        required: `${dic.t("Jclub-First Name is required")}`,
                                        minLength: {
                                            value:3,
                                            message: `${dic.t("Jclub-Enter at least 3 Characters")}`
                                        },
                                        pattern:{
                                            value:letters,
                                            message: `${dic.t("Jclub-Please enter only letters")}`
                                        } 
                                        })} autoComplete="off" disabled={isLoading} />
                                    {errors.firstName && errors.firstName.message && <span className="error text-danger">{errors.firstName.message}</span>}
                                </div>
                                <div className='col-lg-6 col-md-4 col-5 col-sm-4 lastname-padding'>
                                    <input type="text" className="form-control" maxLength={20} onKeyUp={formfieldchanged} placeholder={`${dic.t("Jclub-Last Name").toUpperCase()}`} {...register("lastName", { 
                                        required: `${dic.t("Jclub-Last Name is required")}`, 
                                        minLength: {
                                            value:3,
                                            message: `${dic.t("Jclub-Enter at least 3 Characters")}`
                                        },
                                        pattern: {
                                            value:letters,
                                            message:`${dic.t("Jclub-Please enter only letters")}`
                                        } })} autoComplete="off" disabled={isLoading} />
                                    {errors.lastName && errors.lastName.message && <span className="error text-danger">{errors.lastName.message}</span>}
                                </div>
                            </div>
                            <div className='row pt-3 pb-3'>
                                <div className='col-2 col-md-2 col-sm-2'></div>
                                <div className='col-lg-6 col-md-4 col-5 col-sm-4 firstname-padding'>
                                    <input type="text" className="form-control" maxLength={75} onKeyUp={formfieldchanged} placeholder={`${dic.t("Jclub-Email Address").toUpperCase()}`} {...register("email", {
                                required: 'Email is Required',
                                pattern: {
                                    value: emailPattern,
                                    message: `${dic.t("Jclub-Please enter a valid email")}`,
                                },
                            })} autoComplete="off" disabled={isLoading} />
                                    {errors.email && errors.email.message && <span className="error text-danger">{errors.email.message}</span>}
                                </div>
                                <div className='col-lg-6 col-md-4 col-5 col-sm-4 firstname-padding'>
                                    <input type="text" className="form-control" maxLength={10}   onKeyUp={formfieldchanged} placeholder={`MOBILE NUMBER`} {...register("phone", {
                                required: 'Mobile Number is Required',
                                pattern: {
                                    value: mobilePattern,
                                    message: `Please enter a valid mobile number`,
                                },
                            })} value={getContact} onChange={handleCheckNumber} autoComplete="off" disabled={isLoading} />
                                    {errors.phone && errors.phone.message && <span className="error text-danger">{errors.phone.message}</span>}
                                </div>
                            </div>
                            <div className='row pb-2'>
                                <div className='col-2 col-md-2 col-sm-2'></div>
                                <div className='col-lg-6 col-md-4 col-5 col-sm-4 firstname-padding'>
                                    <Controller
                                        name="jclubLocation"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => <Select  {...field} options={options} placeholder={'INTERESTED CLUB'} styles={colourStyles}  />}
                                    />
                                    {errors.jclubLocation && errors.jclubLocation.type === "required" && <span className="error text-danger">{`This field is required`}</span>}
                                    {isSubmitted && !(errors.phone || errors.email || errors.firstName || errors.lastName) && <p className="error text-success text-center">{dic.t("Jclub-Thank you for your subscription")}</p>}
                                    {isnotSubmitted && <p className='text-error text-center'>Please try again later...</p>}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-2 col-md-2 col-sm-2'></div>
                                <div className='col-lg-12 col-md-8 pt-2 col-sm-8 col-10 submit-button-newsletter'>
                                    <button className="btn" disabled={isLoading}>
                                        {isLoading && ( <HiRefresh icon="spinner" className="spinner" /> )}
                                        {isLoading && "Submitting..."}
                                        {!isLoading && dic.t("Jclub-Submit")}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsletterMobile