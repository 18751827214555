import React from "react";
import AddressCard from "./AddressCard";
import "./style.scss";
function ContactUS(props) {

  const contactus = props.fields["Contact Us List"];
  const title = props && props.fields && props.fields.Title && props.fields.Title.value ;

  return (
    <div className="contact-us-main">
      <div className="container-fluid px-5">
        <h2 className="m-0 p-0 py-5 text-center small-description">
          {title}
        </h2>

        <div className="row text-white address-rows">
          {
            contactus.map( (items,key) => {
              const image = items && items.fields["Location Image"] && items.fields["Location Image"].value.src;
              const hotelname = items && items.fields["Hotel"] && items.fields["Hotel"].fields.Title.value;
              const address = items && items.fields["Address"] && items.fields["Address"].value;
              const phone = items && items.fields["Phone Number"] && items.fields["Phone Number"].value;
              const email = items && items.fields["Email Address"] && items.fields["Email Address"].value;
              const time = items && items.fields["Working Hours"] && items.fields["Working Hours"].value;
              const direction = items && items.fields["Direction Link"] && items.fields["Direction Link"].value.href;
              return  <AddressCard 
                      keyvalue={key}
                      image={image}  
                      hotelname={hotelname}
                      address={address}
                      phone={phone}
                      email={email}
                      time={time}
                      direction={direction}
                  />
            })
          }
         </div>
      </div>
    </div>
  );
}

export default ContactUS;
