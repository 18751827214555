import React from 'react';
import UpcomingEvents from '../UpcomingEvents';

const Blog = (props) => {
  const blogs  = props && props.fields && props.fields["Blogs"];
  const title = props && props.fields && props.fields.Title.value;
  return (
    <div>
      {
        blogs && blogs.length > 0 ? <UpcomingEvents events={blogs} urltitle={''} title={title}  /> : ""
      }
    </div>
  )
};

export default Blog;
