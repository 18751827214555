import React from "react";
import "./Info.scss";
import Newsletter from "./Newsletter/Newsletter";
import { useMediaQuery } from "react-responsive";
import NewsletterMobile from "./Newsletter/NewsletterMobile";

const Info = (props) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isBlog =
    props &&
    props.fields &&
    props.fields["Title"] &&
    props.fields["Title"].value;
  return (
    <React.Fragment>
      {isBlog !== "J CLUB BLOG" ? (
        <div className="info-section hello">
          <div
            dangerouslySetInnerHTML={{
              __html:
                props &&
                props.fields &&
                props.fields["Download Details"] &&
                props.fields["Download Details"].value,
            }}
          ></div>
          <div
            className="info-title mx-auto py-0"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h2>{props.fields.Title.value}</h2>
          </div>
          <div className="info-text">
            <div
              className="info-description my-0"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    props &&
                    props.fields.Description &&
                    props.fields.Description.value,
                }}
              ></p>
            </div>
          </div>
          <div>
            {isMobile && (
              <div className="newsletter-top-mobile">
                <NewsletterMobile title={"ENQUIRE NOW"} mobile={isMobile} />
              </div>
            )}
          </div>
          <div>
            {!isMobile && (
              <div className="newsletter-top-desktop">
                <Newsletter title={"ENQUIRE NOW"} mobile={isMobile} />
              </div>
            )}
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html:
                props &&
                props.fields &&
                props.fields["Portal Details"] &&
                props.fields["Portal Details"].value,
            }}
          ></div>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default Info;
