import React, { Fragment } from "react";
import dic from "i18next";
import "./style.scss";

import Testimonials from "../Testimonials";

const PersonalTrainersProfile = (props) => {
  const Specialities = props && props.fields.Trainer.fields.Specialities;
  const Qualifications =
    props && props.fields.Qualifications && props.fields.Qualifications.value;
  const mantra = props && props.fields.Montra && props.fields.Montra.value;
  const name =
    props && props.fields.Trainer && props.fields.Trainer.fields.Name.value;
  // const location =
  //   props &&
  //   props.fields.Trainer &&
  //   props.fields.Trainer.fields["Hotel Name"].fields.Title.value;
  const src =
    props &&
    props.fields.Trainer &&
    props.fields.Trainer.fields["Background Image"].value.src;
  // const experience =props && props.fields["Year Of Experience"] &&  props.fields["Year Of Experience"].value;

  return (
    <Fragment>
      <div className="personal-trainers-profile">
        <div className="container ">
          <div className="row mt-5">
            {/* <div className="col-lg-1"></div> */}
            <div className="col-md-4  col-12 left-sub-parent">
              <p className="profile-name p-0 m-0">{name}</p>
              {/* <p className="location-profile">{location}</p> */}
              <br />
              <img
                src={src}
                className="img-fluid profile-image w-75"
                alt="profile"
              />
            </div>
            <div className="col-md-8   col-12 position-relative right-sub-parent mobile-p-0-and-m-0">
              <div className="row profile-paragraph-adjust ">
                <div className="col-md-6 col-sm-4 col-lg-4 col-12">
                  {/* <div className="pb-4">
                    <p className="profile-heading">{dic.t("Jclub-Experience")}</p>
                    <p className="profile-paragraph">{experience}</p>
                  </div> */}
                  {
                   Specialities ?
                      <div className="pb-2">
                        <p className="profile-heading">{dic.t("Jclub-Specialities")}</p>
                        <div className="profile-paragraph">
                          <ul>
                            {Specialities &&
                              Specialities.map((item, index) => {
                                return <li key={index}>{item.fields.Title.value}</li>;
                              })}
                          </ul>
                        </div>
                      </div>
                    : <></>
                  }
                  

                  {
                    mantra ? <div className="pb-2">
                      <p className="profile-heading">{dic.t("Jclub-Mantra")}</p>
                      <p className="profile-paragraph">"{mantra}"</p>
                    </div>
                      : <></>
                  }

                </div>

                  { Qualifications ?
                        <div className="col-md-6 col-sm-6 col-lg-6 col-12 mb-col-4 qualification-margin-adjust  ">
                          <div>
                            <p className="profile-heading">{dic.t("Jclub-Qualifications")}</p>
                            <div className="profile-paragraph">
                              <p
                                dangerouslySetInnerHTML={{ __html: Qualifications }}
                              ></p>
                            </div>
                          </div>
                        </div>
                      : <></>
                  }
              </div>
            </div>
          </div>
        </div>
      </div>
      <Testimonials list={props && props.fields["Testimonial List"]} />
    </Fragment>
  );
};

export default PersonalTrainersProfile;
