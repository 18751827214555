import React, { Fragment } from "react";
import dic from "i18next";
import "./NotFoundFAQ.scss";

const NotFoundFAQ = () => {
  return (
    <Fragment>
      <div className="not-found-title">{dic.t("Not Found FAQ")}</div>
    </Fragment>
  );
};

export default NotFoundFAQ;